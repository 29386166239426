import { NavPath } from 'components/navigation/navigation.props';

export const filterNavigationPaths = (
    navItem: NavPath,
    isSecurePage = false,
    isLoggedIn = false,
    isMembership = false,
    isUmichSite = false
) => {
    let showItem =
        isSecurePage || isLoggedIn
            ? isMembership
                ? navItem.requiresMembership || navItem.requiresAccountAuth
                : navItem.requiresAccountAuth
            : !navItem.requiresAccountAuth && !navItem.requiresMembership;

    // If we receive 0 in Umich display value, we will hide the element for Umich site.
    if (navItem.umichDisplay === 0 && isUmichSite) {
        showItem = false;
    }

    // If we receive 1 in Umich display value, we will be available only for Umich site.
    if (navItem.umichDisplay === 1 && !isUmichSite) {
        showItem = false;
    }
    return showItem;
};
