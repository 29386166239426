import { NavPath } from './navigation.props';

// Temporary solution to create a navigation menu
// for DRX-2650. Drupal integration will be worked
// in a separated ticket DRX-2612.

// Getting a similar structure than what drupal
// may return for an easy future implementation.

export const leftNavigationPaths: NavPath[] = [
    // Pharmacy menu
    {
        id: 1,
        isButton: false,
        label: 'Pharmacy',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        umichDisplay: 0
    },
    {
        id: 2,
        isButton: false,
        label: 'Find lower drug prices, free and flexible delivery options, and expert help when you need it.',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 1,
        umichDisplay: 0
    },
    {
        id: 3,
        isButton: false,
        link: {
            url: '/mail-order'
        },
        label: 'Birdi - Mail Order',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 1,
        umichDisplay: 0
    },
    {
        id: 4,
        isButton: false,
        link: {
            url: '/mail-order/how-it-works'
        },
        label: 'How Does Mail Order Work',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 1,
        umichDisplay: 0
    },
    {
        id: 5,
        isButton: false,
        link: {
            url: '/specialty'
        },
        label: 'Specialty by Birdi',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 1,
        umichDisplay: 0
    },
    {
        id: 6,
        isButton: true,
        link: {
            url: '/get-started/mail-order'
        },
        label: 'Create Mail Order Account',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 1,
        isMobile: false,
        umichDisplay: 0
    },
    {
        id: 17,
        isButton: false,
        link: {
            url: '/get-started/mail-order'
        },
        label: 'Create Mail Order Account',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 1,
        isMobile: true,
        umichDisplay: 0
    },
    // Birdi care menu
    {
        id: 7,
        isButton: false,
        label: 'Birdi Care',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        umichDisplay: 0
    },
    {
        id: 8,
        isButton: false,
        label: 'Birdi Care is an all-in-one healthcare membership with low-cost drugs, free shipping, and monthly telemedicine visits.',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 9,
        isButton: false,
        link: {
            url: '/birdi-care/erectile-dysfunction'
        },
        label: 'Erectile Dysfunction',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 10,
        isButton: false,
        link: {
            url: '/birdi-care/family-care'
        },
        label: 'Family Care',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 11,
        isButton: false,
        link: {
            url: '/birdi-care/urgent-care'
        },
        label: 'Urgent Care',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 12,
        isButton: false,
        link: {
            url: '/birdi-care/uti'
        },
        label: 'UTI',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 13,
        isButton: false,
        link: {
            url: '/birdi-care/what-we-treat'
        },
        label: 'What We Treat',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 14,
        isButton: false,
        link: {
            url: '/birdi-care/how-it-works'
        },
        label: 'How It Works',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 15,
        isButton: false,
        link: {
            url: '/birdi-care/explore-plans'
        },
        label: 'Explore Plans',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        umichDisplay: 0
    },
    {
        id: 16,
        isButton: true,
        link: {
            url: '/get-started/membership'
        },
        label: 'Get Birdi Care',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        isMobile: false,
        umichDisplay: 0
    },
    {
        id: 18,
        isButton: false,
        link: {
            url: '/get-started/membership'
        },
        label: 'Get Birdi Care',
        requiresAccountAuth: false,
        requiresMembership: false,
        langcode: 'en',
        menu_parent: 7,
        isMobile: true,
        umichDisplay: 0
    }
];
