import { useLocation } from '@reach/router';
import { GATSBY_CLIVAR } from 'gatsby-env-variables';

const useUmichSite = () => {
    const location = useLocation();
    const subdomain = location.host.split('.')[0];
    return GATSBY_CLIVAR === 'birdi_umich' || !!(subdomain && subdomain === 'umich');
};

export default useUmichSite;
