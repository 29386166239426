import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { accountHealthConditionsSelector } from 'state/account/account.selectors';
import { membershipHealthConditionsSelector } from 'state/medical-conditions/medical-conditions.selector';
import { Allergies, MedicalConditions } from 'types/health-profile';

export interface UseHealthConditions {
    allergyChoices: string[];
    existingAllergies: string[];
    existingFreeformAllergies: string;
    conditionChoices: string[];
    existingConditions: string[];
    existingFreeformConditions: string;
    ePostPatientNum?: string;
}

export const removeNoneConditions = (reducedConditions: string[]) => {
    if (reducedConditions.length > 1 && reducedConditions.includes('None')) {
        return reducedConditions.filter((condition) => condition !== 'None');
    }
    return reducedConditions;
};

export const reduceExistingConditions = (conditions: MedicalConditions | Allergies) => {
    const reducedConditions = Object.entries(conditions)
        .map((keyValPair) => {
            const key = keyValPair[0];
            const val = keyValPair[1];

            if (typeof val === 'boolean' && Boolean(val) && key.toLocaleLowerCase() !== 'messagestatus') {
                return key;
            }
            return '';
        })
        .filter((cond) => cond && cond.length > 0)
        .sort();

    return removeNoneConditions(reducedConditions);
};

export const useHealthConditions = (isMembershipHealthConditions?: boolean) => {
    const memberShipHealthConditions = useSelector(membershipHealthConditionsSelector);
    const accountHealthConditions = useSelector(accountHealthConditionsSelector);

    const healthConditions = isMembershipHealthConditions
        ? memberShipHealthConditions.healthConditions
        : accountHealthConditions.healthConditions;

    const ePostPatientNum = useMemo(
        () => healthConditions?.medicalConditions.ePostPatientNum || healthConditions?.allergies.ePostPatientNum,
        [healthConditions]
    );

    const existingAllergies = useMemo(() => {
        if (!healthConditions) return [];
        return reduceExistingConditions(healthConditions.allergies);
    }, [healthConditions]);
    const existingConditions = useMemo((): string[] => {
        if (!healthConditions) return [];
        return reduceExistingConditions(healthConditions.medicalConditions);
    }, [healthConditions]);

    //  temporary values
    const tempExistingFreeformConditions = useMemo(() => {
        if (!healthConditions) return '';
        return healthConditions.medicalConditions.TemporaryOther;
    }, [healthConditions]);
    const tempExistingFreeformAllergies = useMemo(() => {
        if (!healthConditions) return '';
        return healthConditions.allergies.TemporaryOther;
    }, [healthConditions]);

    //  reduces condition choices to string array
    const reduceConditions = (conditions: MedicalConditions | Allergies) => {
        return Object.entries(conditions)
            .map((keyValPair) => {
                const key = keyValPair[0];
                const val = keyValPair[1];

                if (typeof val === 'boolean' && key !== 'messageStatus') {
                    return key;
                }
                return '';
            })
            .filter((cond) => cond && cond.length > 0)
            .sort();
    };
    const allergyChoices = useMemo(() => {
        if (!healthConditions) return [];
        const reducedConditions = reduceConditions(healthConditions.allergies);
        const none = reducedConditions.find((condition: string) => condition === 'None');
        return [none, ...reducedConditions.filter((condition: string) => condition !== 'None')];
    }, [healthConditions]);
    const conditionChoices = useMemo(() => {
        if (!healthConditions) return [];
        const reducedConditions = reduceConditions(healthConditions.medicalConditions);
        const none = reducedConditions.find((condition: string) => condition === 'None');
        return [none, ...reducedConditions.filter((condition: string) => condition !== 'None')];
    }, [healthConditions]);

    //  existing conditions
    const existingFreeformConditions = useMemo(() => {
        if (!healthConditions || !healthConditions.allergies) return '';
        return healthConditions.medicalConditions.Other;
    }, [healthConditions]);
    const existingFreeformAllergies = useMemo(() => {
        if (!healthConditions || !healthConditions.medicalConditions) return '';
        return healthConditions.allergies.Other;
    }, [healthConditions]);

    //  booleans
    const userHasNotSubmittedConditions = useMemo(() => {
        return (
            existingConditions.length === 0 &&
            (!existingFreeformConditions || existingFreeformConditions.length === 0) &&
            (!tempExistingFreeformConditions || tempExistingFreeformConditions.length === 0)
        );
    }, [existingConditions, existingFreeformConditions, tempExistingFreeformConditions]);
    const userHasNotSubmittedAllergies = useMemo(() => {
        return (
            existingAllergies.length === 0 &&
            (!existingFreeformAllergies || existingFreeformAllergies.length === 0) &&
            (!tempExistingFreeformAllergies || tempExistingFreeformAllergies.length === 0)
        );
    }, [existingAllergies, existingFreeformAllergies, tempExistingFreeformAllergies]);

    return {
        allergyChoices,
        existingAllergies,
        existingFreeformAllergies,
        conditionChoices,
        existingConditions,
        existingFreeformConditions,
        userHasNotSubmittedAllergies,
        userHasNotSubmittedConditions,
        ePostPatientNum
    };
};
