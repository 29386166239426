/* eslint-disable no-unused-vars */
import moment from 'moment';

import { PrescriptionCardProps } from 'components/prescriptions-list-v2/prescriptions-card/types';

import { ProfileObjectPayload } from 'state/account/account.services';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

import { EasyRefillRxResult } from 'types/easy-refill';

import storageHelper from './storageHelper';

export enum CURRENT_STEP_DESCRIPTION {
    UNKNOWN = 0,
    PROCESSING = 1,
    FILLING_IN_PHARMACY = 2,
    PACKAGED = 3,
    SHIPPED = 4,
    OUT_FOR_DELIVERY = 5,
    DELIVERED = 6
}

// Creating enum to standardize the way we call our rx statuses
// this will be helpful for rx statuses refactor
export enum RX_STATUS {
    NEW_RX = 'NEW_RX',
    REFILL_AVAILABLE = 'REFILL_AVAILABLE',
    IN_CART = 'IN_CART',
    ORDERED = 'ORDERED',
    EXPIRED = 'EXPIRED',
    OUT_OF_REFILLS = 'OUT_OF_REFILLS',
    REFILL_TOO_SOON = 'REFILL_TOO_SOON',
    TRANSFER_PENDING = 'PENDING',
    NEW_REQUEST_PENDING = 'MDO_WAITING',
    ON_HOLD = 'ON_HOLD',
    ORDER_STATUS_PENDING = 'INCOMPLETE/PENDING',
    PENDING_ORDERED = 'PENDING_ORDERED', // Same of order status pending, consider to unify them during refactor
    NOT_INCLUDED = 'NOT_ON_FORMULARY',
    NO_RESPONSE_PRESCRIBER = 'MDO_CANCELLED',
    ASSISTANCE_NEEDED = 'NO_REFILLS'
}

export enum RX_SUB_STATUS {
    PLEASE_CALL_BIRDI = 7,
    AUTH_REQUIRED = 8,
    WAITING_ON_NEW_RX = 9
}

export function addDays(date: string, days: number): Date {
    const origDate = new Date(date);
    origDate.setDate(origDate.getDate() + days);
    return origDate;
}

export function isShipStatusExpired(shipDate: string): Boolean {
    if (!shipDate) return false;
    const showShippedStatusDays = 14;
    const shippedExpirationDate = addDays(shipDate, showShippedStatusDays);
    const today = new Date(Date.now());
    return !!(shippedExpirationDate < today);
}

/**
 * Function to check the order is on hold or ordered/shipped state
 * @param orderLineQueueStatus - Order Line queue status of prescription
 * @returns boolean
 */
export const isOrderLineQueued = (orderLineQueueStatus: string): boolean => {
    if (!orderLineQueueStatus) {
        return true;
    }
    return ['SHIPPED', 'NOTFOUND', 'OPEN'].includes(orderLineQueueStatus);
};

export const isRxOrderedInProgress = (rx: PrescriptionObjectPayload) => {
    return (
        rx.webEligibilityStatus === 'NOT_ELIGIBLE' &&
        (rx.itemInWorkflow === true ||
            (isOrderLineQueued(rx.orderLineQueueStatus) &&
                rx.itemInWorkflow === false &&
                !isShipStatusExpired(rx.lastFillDate)))
    );
};

export const isRxOnHold = (rx: PrescriptionObjectPayload | EasyRefillRxResult) => {
    return rx.webEligibilityStatus === 'NOT_ELIGIBLE' && rx.itemInWorkflow === false && rx.orderQueueStatus === 'HOLD';
};

export const isRxWhiteCard = (orderStatus: PrescriptionCardProps['orderStatus']) => {
    return ['NOT_ON_FORMULARY', 'PENDING', 'MDO_WAITING', 'MDO_CANCELLED', 'ON_HOLD', 'NEW_RX'].includes(orderStatus);
};

export function canRxBeCancelled(rx: PrescriptionObjectPayload, orderStatus: string): boolean {
    // The Rx can't be cancelled if the status is PENDING
    if (rx.webEligibilityStatus === 'PENDING') return false;

    // Button shouldn't be shown if item was added to the cart
    if (rx.inOrderCart) {
        return false;
    }

    // The Rx can be cancelled if is not in formulary
    if (!rx.inFormulary) return true;

    // ORDERED prescriptions with status processing can't be cancelled
    if (['PENDING_ORDERED', 'ORDERED', 'SHIPPED'].includes(orderStatus) && rx.rxSubStatus?.CurrentStep === 1)
        return false;

    // Otherwise, rx can be canceled if is not any of the following statuses
    const isInProgressStatus = ['PACKAGED', 'SHIP READY', 'SHIPPED', 'OPEN'].includes(orderStatus);

    return !isInProgressStatus;
}

export function isRxExpired(expirationDate: string): boolean {
    const today = moment(new Date()).format('MM/DD/YYYY');
    return moment(expirationDate).isBefore(today, 'day');
}

export function isRxCloseToExpire(expirationDate: string): boolean {
    const inputDate = moment(expirationDate, 'MM/DD/YYYY');
    const today = moment();
    if (inputDate.isAfter(today) && inputDate.diff(today, 'days') <= 20) {
        return true;
    }
    return false;
}

export function isRxExpiresToday(expirationDate: string): boolean {
    const inputDate = moment(expirationDate, 'MM/DD/YYYY');
    const today = moment();
    return inputDate.isSame(today, 'day');
}

export function isRxRefillTooSoonStatus(rx: PrescriptionObjectPayload): boolean {
    return (
        rx.webEligibilityStatus === 'NOT_ELIGIBLE' &&
        rx.nextFillDate !== undefined &&
        rx.itemInWorkflow === false &&
        (isShipStatusExpired(rx.lastFillDate) as boolean)
    );
}

export function isRxRefillAvailableStatus(rx: PrescriptionObjectPayload): boolean {
    return rx.webEligibilityStatus === 'ELIGIBLE' && rx.rxStatus !== 'Profiled';
}

export function isRxOutOfRefillsStatus(rx: PrescriptionObjectPayload): boolean {
    return (
        rx.webEligibilityStatus === 'AUTH_REQ' && !isRxExpired(rx.rxExpirationDate) && Number(rx.fillsRemaining) === 0
    );
}

export function isRxExpiredStatus(rx: PrescriptionObjectPayload): boolean {
    return rx.webEligibilityStatus === 'AUTH_REQ' && isRxExpired(rx.rxExpirationDate);
}

export function isRxNotInFormulary(rx: PrescriptionObjectPayload, accountHasInsurance: boolean): boolean {
    return !accountHasInsurance && !rx.inFormulary;
}

export function isRxNew(rx: PrescriptionObjectPayload): boolean {
    const rxIdentifier = rx.rxNumber;
    const { prescriptions } = storageHelper.local.getNewRxs();

    if (prescriptions) {
        return prescriptions.includes(rxIdentifier);
    }

    return false;
}

export function hasPlanAutoRefillFlag(epostNum: string, profileObject: ProfileObjectPayload): boolean {
    if (profileObject && epostNum === profileObject.epostPatientNum) {
        return profileObject.patientPlanAutoRefillFlag;
    }

    if (profileObject && profileObject.isCaregiver) {
        const dependentAccount = profileObject.dependents.find((dependent) => dependent.epostPatientNum === epostNum);
        return dependentAccount ? dependentAccount.patientPlanAutoRefillFlag : false;
    }

    return false;
}

// Validate mapped Rx status (from payloadToProps) to know
// if the card should show the Rx price.
export function shouldRxShowPrice(status: string) {
    return ![
        RX_STATUS.TRANSFER_PENDING,
        RX_STATUS.NEW_REQUEST_PENDING, // New status
        RX_STATUS.ON_HOLD,
        RX_STATUS.ORDER_STATUS_PENDING,
        RX_STATUS.NOT_INCLUDED,
        RX_STATUS.NO_RESPONSE_PRESCRIBER,
        RX_STATUS.ASSISTANCE_NEEDED // New status
    ].find((invalidStatus) => invalidStatus === status);
}

// Check if the price exists for the given prescription number.
export const findPriceMatch = (rxNumber: string, drugDiscountPrices: { price: string; rxNumber: string }[]) => {
    const priceMatches = drugDiscountPrices.find((item) => item.rxNumber === rxNumber);
    return priceMatches ? priceMatches.price : '';
};
