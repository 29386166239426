import { CSSProperties } from 'react';

import { AlertIconProps } from './alert-icon.props';
import './alert-icon.style.scss';

const AlertIcon = ({ className, style, variant }: AlertIconProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'light': {
                return { fill: '#FFFFFF', height: '25', width: '25' };
            }
            case 'dark': {
                return { fill: 'rgb(34,42,63)', height: '25', width: '25' };
            }
            case 'alert': {
                return { fill: '#F04C34', height: '24', width: '24' };
            }
            case 'info': {
                return { fill: '#1A497F', height: '24', width: '24' };
            }
            case 'warning': {
                return { fill: '#EF7B4B', height: '24', width: '24' };
            }
            default: {
                return { fill: '#FFFFFF', height: '100%', width: '100%' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <div className={className} style={style}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <rect width="25" height="25" fill="varian" />
                    <path
                        d="M10.2513 2.44241L0.316317 20.5462C0.108794 20.9244 0 21.3488 0 21.7801C0 23.1966 1.14827 24.3449 2.56474 24.3449H22.4348C22.8661 24.3449 23.2905 24.2361 23.6687 24.0285C24.9104 23.3471 25.3647 21.788 24.6832 20.5462L14.7482 2.44241C14.5134 2.0146 14.1615 1.66265 13.7337 1.42788C12.4919 0.746421 10.9328 1.20064 10.2513 2.44241ZM12.9111 2.92678C13.0537 3.00504 13.171 3.12236 13.2492 3.26496L23.1842 21.3688C23.4114 21.7827 23.26 22.3024 22.8461 22.5295C22.72 22.5987 22.5786 22.635 22.4348 22.635H2.56474C2.09258 22.635 1.70983 22.2522 1.70983 21.7801C1.70983 21.6363 1.74609 21.4948 1.81526 21.3688L11.7503 3.26496C11.9641 2.87539 12.437 2.71835 12.837 2.8906L12.9111 2.92678ZM12.7072 16.6506C13.4556 16.6506 13.6427 16.4911 13.6801 15.8531L13.9234 8.89897C13.9421 8.26097 13.755 8.10148 13.0066 8.10148H11.9775C11.2291 8.10148 11.042 8.26097 11.0794 8.89897L11.3039 15.8531C11.3414 16.4911 11.5285 16.6506 12.2769 16.6506H12.7072ZM12.4998 20.6402C13.425 20.6402 13.9246 20.0403 13.9246 19.2341C13.9246 18.4655 13.425 17.7905 12.4998 17.7905C11.556 17.7905 11.0749 18.4655 11.0749 19.2341C11.0749 20.0403 11.556 20.6402 12.4998 20.6402Z"
                        fill={variantStyle.fill}
                    />
                    <path
                        d="M10.2513 2.44241L0.316317 20.5462C0.108794 20.9244 0 21.3488 0 21.7801C0 23.1966 1.14827 24.3449 2.56474 24.3449H22.4348C22.8661 24.3449 23.2905 24.2361 23.6687 24.0285C24.9104 23.3471 25.3647 21.788 24.6832 20.5462L14.7482 2.44241C14.5134 2.0146 14.1615 1.66265 13.7337 1.42788C12.4919 0.746421 10.9328 1.20064 10.2513 2.44241ZM12.9111 2.92678C13.0537 3.00504 13.171 3.12236 13.2492 3.26496L23.1842 21.3688C23.4114 21.7827 23.26 22.3024 22.8461 22.5295C22.72 22.5987 22.5786 22.635 22.4348 22.635H2.56474C2.09258 22.635 1.70983 22.2522 1.70983 21.7801C1.70983 21.6363 1.74609 21.4948 1.81526 21.3688L11.7503 3.26496C11.9641 2.87539 12.437 2.71835 12.837 2.8906L12.9111 2.92678ZM12.7072 16.6506C13.4556 16.6506 13.6427 16.4911 13.6801 15.8531L13.9234 8.89897C13.9421 8.26097 13.755 8.10148 13.0066 8.10148H11.9775C11.2291 8.10148 11.042 8.26097 11.0794 8.89897L11.3039 15.8531C11.3414 16.4911 11.5285 16.6506 12.2769 16.6506H12.7072ZM12.4998 20.6402C13.425 20.6402 13.9246 20.0403 13.9246 19.2341C13.9246 18.4655 13.425 17.7905 12.4998 17.7905C11.556 17.7905 11.0749 18.4655 11.0749 19.2341C11.0749 20.0403 11.556 20.6402 12.4998 20.6402Z"
                        fill={variantStyle.fill}
                    />
                </g>
            </svg>
        </div>
    );
};

export const BlueCircleAlertIcon = ({ style }: { style?: CSSProperties }) => {
    return <AlertIcon className="slate-circle-alert-icon" style={style} />;
};

export const RedCircleAlertIcon = ({ style }: { style?: CSSProperties }) => {
    const iconStyles = {
        ...style,
        width: '60px',
        height: '60px'
    };
    return <AlertIcon className="red-circle-alert-icon" style={iconStyles} />;
};

export default AlertIcon;
