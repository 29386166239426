import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import moment from 'moment';

import { CreditCardPayload } from 'state/account/account.services';

import { formatPrice } from 'schema/price.schema';

import {
    MembershipAddedResponse,
    MembershipBirdiSelectFormularyPayload,
    MembershipDetails,
    MembershipGenerateScheduleLinkPayload,
    MembershipInvitationsResponse,
    MembershipPaymentMethodPayload,
    MembershipPersonalDetailsFormValues,
    MembershipPlansDetails,
    MembershipPlansDetailsResponse,
    MembershipPreAuthorizePaymentResponse,
    MembershipPricingDetailsResponse,
    MembershipTelehealthVisitsResponse,
    MembershipUpgradePlanPayload,
    MembershipUpgradePlanResponse,
    MembershipViewVisitsResponse,
    PricingDetails,
    RequestVisitDetails
} from 'types/membership';

import { convertToTitleCase } from 'util/string';

import {
    membershiGenerateScheduleLinkRoutine,
    membershipAcceptOrDeclineInvitationsRoutine,
    membershipAddMemberRoutine,
    membershipApplyPromoCodeRoutine,
    membershipBirdiSelectFormularyRoutine,
    membershipDetailsRoutine,
    membershipGetInvitationsRoutine,
    membershipGetTelehealthVisitsRoutine,
    membershipGetUpgradablePlansRoutine,
    membershipGetVisitsLinkRoutine,
    membershipPaymentMethodsRoutine,
    membershipPlansDetailsRoutine,
    membershipPreAuthorizePaymentRoutine,
    membershipRemoveInviteeRoutine,
    membershipRequestVisitMemberIdRoutine,
    membershipSendInviteRoutine,
    membershipUpdateDefaultPaymentMethodRoutine,
    membershipUpgradePlanRoutine,
    membershipVerifyDependentRoutine
} from './membership.routines';
import { MembershipPlanId } from './membership.selector';

export interface SelectUpgradablePlanState {
    membershipPlanId: number;
    epostPatientNum: string;
    cardSeqNum: string;
    newValuePlan: number;
    chargeAmount: number;
    termlength?: number;
    promoCode?: string;
}

export type MembershipState = {
    dependentToAdd?: {
        accountType: number;
        dateOfBirth: string;
        email?: string;
        firstName: string;
        insuranceMemberId: string;
        lastName: string;
        phoneNumber?: string;
        prescriptionNumber?: string;
        ePostPatientNumber?: string;
        age?: number;
    };
    memberToAdd?: MembershipAddedResponse;
    membershipDetails?: MembershipDetails;
    plansDetails?: MembershipPlansDetails;
    requestVisitDetails?: RequestVisitDetails;
    paymentMethods?: MembershipPaymentMethodPayload;
    generateScheduleLink?: MembershipGenerateScheduleLinkPayload;
    previousVisitLink?: string;
    isLoadingDetails: boolean;
    isLoadingPlansDetails: boolean;
    isLoadingAddMember: boolean;
    isLoadingPaymentMethods: boolean;
    isLoadingSendInvite: boolean;
    isLoadingScheduleLink: boolean;
    isLoadingPreviousVisitsLink: boolean;
    isNewMember: boolean;
    isLoadingNewDefaultMembershipPayment: boolean;
    isLoadingUpgadablePlans: boolean;
    isLoadingRemoveInvite: boolean;
    isLoadingUpgradePlan: boolean;
    isLoadingPreAuthPayment: boolean;
    isValidPromoCode?: boolean;
    error: {
        messageText?: string;
    };
    membershipBirdiSelectFormulary: MembershipBirdiSelectFormularyPayload;
    telehealthVisits: MembershipTelehealthVisitsResponse | null;
    upgradePricingDetails: PricingDetails[];
    paymentModel: 'Annually' | 'Monthly';
    selectedPlanToUpgrade: Partial<MembershipUpgradePlanPayload>;
    upgradedPlan: Partial<MembershipUpgradePlanResponse>;
    preAuthPaymentResponse: Partial<MembershipPreAuthorizePaymentResponse>;
    selectedCardForVisit?: CreditCardPayload;
    invitations?: MembershipInvitationsResponse;
    isLoadingInvitations: boolean;
};

const initialState: MembershipState = {
    isLoadingDetails: false,
    isLoadingPlansDetails: false,
    isLoadingAddMember: false,
    isLoadingPaymentMethods: false,
    isLoadingSendInvite: false,
    isLoadingScheduleLink: false,
    isLoadingPreviousVisitsLink: false,
    isNewMember: false,
    isLoadingNewDefaultMembershipPayment: false,
    isLoadingRemoveInvite: false,
    error: {
        messageText: undefined
    },
    membershipBirdiSelectFormulary: {
        drugList: []
    },
    telehealthVisits: null,
    paymentModel: 'Annually',
    selectedPlanToUpgrade: {},
    isLoadingUpgadablePlans: false,
    upgradePricingDetails: [],
    isLoadingUpgradePlan: false,
    upgradedPlan: {},
    isLoadingPreAuthPayment: false,
    preAuthPaymentResponse: {},
    selectedCardForVisit: undefined,
    isValidPromoCode: undefined,
    invitations: undefined,
    isLoadingInvitations: false
};

export const getAccountTypeId = (accountType: string) => {
    switch (accountType) {
        case 'Caregiver':
            return 0;
        case 'Minor':
            return 1;
        case 'Adult':
            return 2;
        case 'Pet':
            return 3;
        default:
            return 99;
    }
};

export const getPaymentFrequency = (frequency: number) => {
    switch (frequency) {
        case 1:
            return 'Monthly';
        case 12:
            return 'Annually';
        case 4:
            return 'Quarterly';
        default:
            return 'Monthly';
    }
};

const membershipSlice = createSlice({
    name: 'membership',
    initialState,
    reducers: {
        isNewMember: (state) => {
            state.isNewMember = true;
        },
        setPayMentModel: (state, { payload }: PayloadAction<any>) => {
            state.paymentModel = payload;
        },
        setSelectedPlan: (state, { payload }: PayloadAction<Partial<SelectUpgradablePlanState>>) =>
            produce(state, (draftState) => {
                draftState.selectedPlanToUpgrade = {
                    epostPatientNum: state.membershipDetails?.epostPatientNum,
                    membershipPlanId: payload.membershipPlanId
                        ? payload.membershipPlanId
                        : state.selectedPlanToUpgrade.membershipPlanId,
                    cardSeqNum: payload.cardSeqNum ? payload.cardSeqNum : state.selectedPlanToUpgrade.cardSeqNum,
                    chargeAmount: payload.chargeAmount
                        ? payload.chargeAmount
                        : state.selectedPlanToUpgrade.chargeAmount,
                    newValuePlan: payload.newValuePlan
                        ? payload.newValuePlan
                        : state.selectedPlanToUpgrade.newValuePlan,
                    promoCode: payload.promoCode ? payload.promoCode : state.selectedPlanToUpgrade?.promoCode,
                    termlength: payload.termlength ? payload.termlength : state.selectedPlanToUpgrade?.termlength
                };
            }),
        setSelectedPaymentForVisit: (state, { payload }: PayloadAction<CreditCardPayload>) => {
            state.selectedCardForVisit = payload;
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(
            membershipVerifyDependentRoutine.SUCCESS,
            (state, { payload }: PayloadAction<Required<MembershipPersonalDetailsFormValues>>) =>
                produce(state, (draftState) => {
                    draftState.dependentToAdd = {
                        accountType: getAccountTypeId(payload.accountType),
                        dateOfBirth: payload.dateOfBirth,
                        email: payload.email,
                        firstName: payload.firstName,
                        insuranceMemberId: '',
                        lastName: payload.lastName,
                        phoneNumber: payload.phoneNumber,
                        prescriptionNumber: '',
                        age: payload.age
                    };
                })
        );
        addCase(membershipDetailsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.membershipDetails = undefined;
                draftState.isLoadingDetails = true;
            })
        );
        addCase(membershipDetailsRoutine.SUCCESS, (state, { payload }: PayloadAction<MembershipDetails>) =>
            produce(state, (draftState) => {
                draftState.membershipDetails = {
                    ...payload,
                    // date formatting
                    lastPaymentDate: payload.lastPaymentDate
                        ? moment(payload.lastPaymentDate).format('MM/DD/YYYY')
                        : null,
                    memberSinceDate: moment(payload.memberSinceDate).format('MM/DD/YYYY'),
                    nextPaymentDate: moment(payload.nextPaymentDate).format('MM/DD/YYYY'),
                    lastPaymentAttemptDate: moment(payload.lastPaymentAttemptDate).format('MM/DD/YYYY'),
                    invitationDate: moment(payload.invitationDate).format('MM/DD/YYYY'),
                    // price formatting
                    nextPaymentAmount: formatPrice(payload.nextPaymentAmount),
                    lastPaymentAmount: formatPrice(payload.lastPaymentAmount),
                    telehealthPrice: formatPrice(payload.telehealthPrice),
                    planName: convertToTitleCase(payload.planName),
                    members: payload?.members?.map((member) => {
                        if (!member.dateGranted) return { ...member };
                        return { ...member, dateGranted: moment(member.dateGranted).format('MM/DD/YYYY') };
                    }),
                    paymentFrequency: getPaymentFrequency(payload.termLengthMonths)
                };
                draftState.isLoadingDetails = false;
            })
        );
        addCase(membershipDetailsRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.membershipDetails = undefined;
                draftState.error.messageText = payload.messageText;
                draftState.isLoadingDetails = false;
            })
        );
        addCase(membershipPlansDetailsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.plansDetails = undefined;
                draftState.isLoadingPlansDetails = true;
            })
        );
        addCase(
            membershipPlansDetailsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipPlansDetailsResponse>) =>
                produce(state, (draftState) => {
                    draftState.plansDetails = {
                        ...payload.plansDetails
                    };
                    draftState.isLoadingPlansDetails = false;
                })
        );
        addCase(membershipPlansDetailsRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.plansDetails = undefined;
                draftState.error.messageText = payload.messageText;
                draftState.isLoadingPlansDetails = false;
            })
        );
        addCase(membershipRequestVisitMemberIdRoutine.TRIGGER, (state, action: PayloadAction<number | null>) =>
            produce(state, (draftState) => {
                if (action.payload === null) {
                    draftState.requestVisitDetails = undefined;
                    return;
                }
                draftState.requestVisitDetails = {
                    memberId: action.payload
                };
            })
        );

        // Add member
        addCase(membershipAddMemberRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddMember = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(membershipAddMemberRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddMember = false;
                if (draftState.isNewMember) {
                    draftState.memberToAdd = payload;
                } else {
                    draftState.dependentToAdd = payload;
                }
            })
        );
        addCase(membershipAddMemberRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddMember = false;
                draftState.error.messageText = payload.messageText;
            })
        );

        // Send Invite
        // Add member
        addCase(membershipSendInviteRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingSendInvite = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(membershipSendInviteRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingSendInvite = false;
            })
        );
        addCase(membershipSendInviteRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingSendInvite = false;
                draftState.error.messageText = payload.messageText;
            })
        );

        // payment Methods
        addCase(membershipPaymentMethodsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingPaymentMethods = true;
                draftState.paymentMethods = undefined;
            })
        );
        addCase(
            membershipPaymentMethodsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipPaymentMethodPayload>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingPaymentMethods = false;

                    const formatCards = payload.cards.map((item) => {
                        return {
                            ...item,
                            isDefaultPaymentCard: item.defaultPaymentCard === 'Y' ? true : false,
                            isFsaCard: item.fsa === 'Y' ? true : false,
                            // @TODO: Fields unavailable for now, as soon as they are released remove these values
                            paymentCardMonth: '2',
                            paymentCardType: 'MASTER CARD'
                        };
                    });

                    draftState.paymentMethods = {
                        ...payload,
                        cards: formatCards
                    };
                })
        );
        addCase(membershipPaymentMethodsRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingPaymentMethods = false;
                draftState.paymentMethods = undefined;
                draftState.error.messageText = payload.messageText;
            })
        );

        addCase(membershiGenerateScheduleLinkRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingScheduleLink = true;
                draftState.generateScheduleLink = undefined;
            })
        );
        addCase(
            membershiGenerateScheduleLinkRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipGenerateScheduleLinkPayload>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingScheduleLink = false;
                    draftState.generateScheduleLink = payload;
                })
        );
        addCase(
            membershiGenerateScheduleLinkRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingScheduleLink = false;
                    draftState.generateScheduleLink = undefined;
                    draftState.error.messageText = payload.messageText;
                })
        );

        // Get previous visits link

        addCase(membershipGetVisitsLinkRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingPreviousVisitsLink = true;
                draftState.previousVisitLink = undefined;
            })
        );
        addCase(
            membershipGetVisitsLinkRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipViewVisitsResponse>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingPreviousVisitsLink = false;
                    draftState.previousVisitLink = payload.link;
                })
        );
        addCase(membershipGetVisitsLinkRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingPreviousVisitsLink = false;
                draftState.previousVisitLink = undefined;
                draftState.error.messageText = payload.messageText;
            })
        );

        addCase(membershipBirdiSelectFormularyRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.membershipBirdiSelectFormulary = {
                    drugList: []
                };
                draftState.isLoadingDetails = true;
            })
        );
        addCase(
            membershipBirdiSelectFormularyRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipBirdiSelectFormularyPayload>) =>
                produce(state, (draftState) => {
                    draftState.membershipBirdiSelectFormulary = {
                        ...payload
                    };
                    draftState.isLoadingDetails = false;
                })
        );
        addCase(
            membershipBirdiSelectFormularyRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.membershipDetails = undefined;
                    draftState.error.messageText = payload.messageText;
                    draftState.isLoadingDetails = false;
                })
        );

        // Get telehealth visits

        addCase(membershipGetTelehealthVisitsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.telehealthVisits = null;
            })
        );
        addCase(
            membershipGetTelehealthVisitsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipTelehealthVisitsResponse>) =>
                produce(state, (draftState) => {
                    draftState.telehealthVisits = payload;
                })
        );
        addCase(
            membershipGetTelehealthVisitsRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.telehealthVisits = null;
                })
        );

        addCase(membershipUpdateDefaultPaymentMethodRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingNewDefaultMembershipPayment = true;
            })
        );
        addCase(membershipUpdateDefaultPaymentMethodRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingNewDefaultMembershipPayment = false;
            })
        );
        addCase(
            membershipUpdateDefaultPaymentMethodRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingNewDefaultMembershipPayment = false;
                    draftState.error.messageText = payload.messageText;
                })
        );

        // Upgrade Plan
        addCase(membershipGetUpgradablePlansRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingUpgadablePlans = true;
            })
        );
        addCase(
            membershipGetUpgradablePlansRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipPricingDetailsResponse>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingUpgadablePlans = false;
                    if (
                        state.membershipDetails?.planId === MembershipPlanId.ON_DEMAND &&
                        ['on demand visit'].includes(state.membershipDetails.planName.toLowerCase())
                    ) {
                        const adaptPlans: { [key: number]: PricingDetails } = {};

                        // TODO: This can be improved with an array reducer, this will
                        // help us to reduce complexity in this foreEach statement.
                        // Upgrade plans based on PlanId
                        payload.PricingDetails.forEach((plan: PricingDetails) => {
                            const existingPlan = adaptPlans[plan.PlanId];
                            const MonthlyPrice = existingPlan?.MonthlyPrice;
                            const AnnualPrice = existingPlan?.AnnualPrice;
                            const MonthlyDiscount = existingPlan?.MonthlyDiscount;
                            const AnnualDiscount = existingPlan?.AnnualDiscount;
                            const MonthlyInitialChargeAmount = existingPlan?.MonthlyInitialChargeAmount;
                            const AnnualInitialChargeAmount = existingPlan?.AnnualInitialChargeAmount;

                            if (existingPlan) {
                                // If plan exists, update recurring billing based on TypeOfRecurrence
                                existingPlan.MonthlyPrice =
                                    plan.TypeOfRecurrence === 1 ? plan.RecurringBilling : MonthlyPrice;
                                existingPlan.AnnualPrice =
                                    plan.TypeOfRecurrence === 12 ? plan.RecurringBilling : AnnualPrice;

                                existingPlan.MonthlyDiscount =
                                    plan.TypeOfRecurrence === 1 ? plan.DiscountRecurringBilling : MonthlyDiscount;
                                existingPlan.AnnualDiscount =
                                    plan.TypeOfRecurrence === 12 ? plan.DiscountRecurringBilling : AnnualDiscount;
                                existingPlan.InitialChargeAmount = plan.InitialChargeAmount
                                    ? plan.InitialChargeAmount
                                    : undefined;
                                existingPlan.MonthlyInitialChargeAmount =
                                    plan.TypeOfRecurrence === 1 ? plan.InitialChargeAmount : MonthlyInitialChargeAmount;
                                existingPlan.AnnualInitialChargeAmount =
                                    plan.TypeOfRecurrence === 12 ? plan.InitialChargeAmount : AnnualInitialChargeAmount;
                            } else {
                                // If plan does not exist, create new plan object
                                adaptPlans[plan.PlanId] = {
                                    PlanId: plan.PlanId,
                                    PlanName: plan.PlanName,
                                    JoinFee: plan.JoinFee,
                                    MonthlyPrice: plan.TypeOfRecurrence === 1 ? plan.RecurringBilling : undefined,
                                    AnnualPrice: plan.TypeOfRecurrence === 12 ? plan.RecurringBilling : undefined,
                                    MonthlyInitialChargeAmount:
                                        plan.TypeOfRecurrence === 1 ? plan.InitialChargeAmount : undefined,
                                    AnnualInitialChargeAmount:
                                        plan.TypeOfRecurrence === 12 ? plan.InitialChargeAmount : undefined,
                                    MonthlyDiscount:
                                        plan.TypeOfRecurrence === 1 && plan.DiscountRecurringBilling
                                            ? plan.DiscountRecurringBilling
                                            : undefined,
                                    AnnualDiscount:
                                        plan.TypeOfRecurrence === 12 && plan.DiscountRecurringBilling
                                            ? plan.DiscountRecurringBilling
                                            : undefined,
                                    ChargeAmount:
                                        state.paymentModel === 'Monthly' && plan.TypeOfRecurrence === 12
                                            ? (MonthlyPrice as number)
                                            : (AnnualPrice as number),
                                    RecurringBilling: 0,
                                    TypeOfRecurrence: 0
                                };
                            }
                        });

                        // Convert aggregated plans object to array
                        draftState.upgradePricingDetails = Object.values(adaptPlans);
                    } else {
                        draftState.upgradePricingDetails = payload.PricingDetails;
                    }
                })
        );

        addCase(
            membershipGetUpgradablePlansRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingUpgadablePlans = false;
                    draftState.error.messageText = payload.messageText;
                })
        );

        // Apply promoCode

        addCase(membershipApplyPromoCodeRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isValidPromoCode = true;
            })
        );

        addCase(membershipApplyPromoCodeRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isValidPromoCode = false;
            })
        );

        addCase(membershipApplyPromoCodeRoutine.FULFILL, (state) =>
            produce(state, (draftState) => {
                draftState.isValidPromoCode = undefined;
            })
        );

        addCase(membershipRemoveInviteeRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingRemoveInvite = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(membershipRemoveInviteeRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingRemoveInvite = false;
            })
        );
        addCase(membershipRemoveInviteeRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingRemoveInvite = false;
                draftState.error.messageText = payload.messageText;
            })
        );

        // Upgrade Plan
        addCase(membershipUpgradePlanRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingUpgradePlan = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(
            membershipUpgradePlanRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipUpgradePlanResponse>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingUpgradePlan = false;
                    draftState.upgradedPlan = payload;
                })
        );
        addCase(membershipUpgradePlanRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingUpgradePlan = false;
                draftState.upgradedPlan = {};
                draftState.error.messageText = payload.messageText;
            })
        );

        // PreAuthorize Payment
        addCase(membershipPreAuthorizePaymentRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingPreAuthPayment = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(
            membershipPreAuthorizePaymentRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipPreAuthorizePaymentResponse>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingPreAuthPayment = false;
                    draftState.preAuthPaymentResponse = payload;
                })
        );
        addCase(
            membershipPreAuthorizePaymentRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingPreAuthPayment = false;
                    draftState.error.messageText = payload.messageText;
                })
        );

        addCase(membershipGetInvitationsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingInvitations = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(
            membershipGetInvitationsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipInvitationsResponse>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingInvitations = false;
                    draftState.invitations = payload;
                })
        );
        addCase(membershipGetInvitationsRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingInvitations = false;
                draftState.error.messageText = payload.messageText;
            })
        );

        addCase(membershipAcceptOrDeclineInvitationsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingInvitations = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(
            membershipAcceptOrDeclineInvitationsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MembershipInvitationsResponse>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingInvitations = false;
                    draftState.invitations = undefined;
                })
        );
        addCase(
            membershipAcceptOrDeclineInvitationsRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingInvitations = false;
                    draftState.error.messageText = payload.messageText;
                })
        );
    }
});

export const membershipActions = membershipSlice.actions;
export default membershipSlice.reducer;
