import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import {
    Address,
    AdjudicatedRxes,
    AuthenticateUserResponse,
    CreateOrUpdateAddressRequest,
    EasyRefillCartOrder,
    EasyRefillOrder,
    EasyRefillPatientDataCaregiver,
    EasyRefillPaymetricDetailsPayload,
    EasyRefillResponse,
    EasyRefillRxResult,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPaymentCardDataResponse,
    PaymentCard,
    PlanResult,
    StartEasyRefillOrder,
    VerifyUserResponse
} from 'types/easy-refill';
import { Allergies, HealthConditions, MedicalConditions } from 'types/easy-refill-health-profile';

import { getEasyRefillAllOrderTotal, hasRxInFamily } from './easy-refill.helpers';
import {
    easyRefillAddAddressesRoutine,
    easyRefillAddRxToRefillRoutine,
    easyRefillAuthenticateUserRoutine,
    easyRefillCancelOrderLineRoutine,
    easyRefillCancelOrderRoutine,
    easyRefillFetchHealthConditionsRoutine,
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillGetPatientPlansRoutine,
    easyRefillGetSecretKeyHashRoutine,
    easyRefillPaymetricDetailsRoutine,
    easyRefillRemoveRxToRefillRoutine,
    easyRefillStartOrderRoutine,
    easyRefillSubmitOrderRoutine,
    easyRefillToggleAutoRefillRxStatusRoutine,
    easyRefillUpdateAddressesRoutine,
    easyRefillUpdateAllergiesRoutine,
    easyRefillUpdateCartRoutine,
    easyRefillUpdateMedicalConditionsRoutine,
    easyRefillUpdateShippingRoutine,
    easyRefillVerifyUserRoutine
} from './easy-refill.routines';

export const defaultMedicalConditions = {
    none: false,
    asthma: false,
    copd: false,
    cholesterol: false,
    diabetes: false,
    glaucoma: false,
    heart: false,
    hypertension: false,
    kidney: false,
    stroke: false,
    thyroid: false,
    other: '',
    temporaryOther: '',
    ePostPatientNum: ''
};
export const defaultAllergies = {
    ampicillin: false,
    aspirin: false,
    cephalosporins: false,
    codeine: false,
    erythromycin: false,
    none: false,
    nsaiDs: false,
    penicillin: false,
    quinolones: false,
    sulfa: false,
    tetracyclines: false,
    other: '',
    temporaryOther: '',
    ePostPatientNum: ''
};

export interface AutoRefillFlag {
    rxNumber: string;
    autoRefillEligible: boolean;
    autoRefillEnabled: boolean;
    planAutoRefillEligible: boolean;
}

export interface EasyRefillState {
    userVerified: 'UserNotVerified' | 'UserVerified' | 'MaxAttemptsReached' | 'TokenValid' | 'TokenExpired' | '';
    securityToken: string;
    secretKeyHash: string;
    bearerToken: string;
    error: {
        messageText?: string;
    };
    loadingPrescriptions: boolean;
    prescriptionsLoaded: boolean;
    firstName: string;
    rxResults: EasyRefillRxResult[];
    autoRefillFlags: AutoRefillFlag[];
    epostPlanNum: string;
    planAlias: string;
    loadingAddress: boolean;
    loadingCart: boolean;
    addresses: Address[];
    addressToAddOrUpdate?: Address;
    loadingPaymentCard: boolean;
    paymentCards: PaymentCard[];
    refillOrder: EasyRefillCartOrder;
    adjudicatedRxes: AdjudicatedRxes;
    orderInvoiceNumber: string;
    emailSent: boolean;
    isBusy: boolean;
    epostPatientNum: string;
    paymetricDetails?: EasyRefillPaymetricDetailsPayload | undefined;
    healthConditions: HealthConditions;
    isCaregiver: boolean;
    familyDependents: EasyRefillPatientDataCaregiver[];
    // DRX-2008: Create a initial state for the cart.
    cart?: {
        birdiOrderNumber?: string;
        firstName?: string;
        lastName?: string;
        type?: string;
        epostPatientNum?: string;
        messageErrorText?: string;
        messageStatus?: boolean;
        messageText?: string;
        order: EasyRefillOrder;
    }[];
    orderPlaced: boolean;
    orderNumber: string;
    patientPlans: PlanResult[];
    isRemoveDisabled: boolean;
    isExpired: boolean;
    isToggleBusy?: boolean;
    isFamilyMembersWithRx: boolean;
    totalOrder?: number;
}

const initialState: EasyRefillState = {
    userVerified: '',
    securityToken: '',
    secretKeyHash: '',
    bearerToken: '',
    error: {
        messageText: undefined
    },
    loadingPrescriptions: false,
    prescriptionsLoaded: false,
    firstName: '',
    rxResults: [],
    autoRefillFlags: [],
    epostPlanNum: '',
    planAlias: '',
    loadingAddress: false,
    loadingCart: false,
    addresses: [],
    addressToAddOrUpdate: undefined,
    loadingPaymentCard: false,
    paymentCards: [],
    refillOrder: {
        rxsToRefill: [],
        expeditedShipping: false,
        addressSeqNum: '',
        paymentCardSeqNum: ''
    },
    adjudicatedRxes: {},
    orderInvoiceNumber: '',
    emailSent: false,
    isBusy: false,
    epostPatientNum: '',
    healthConditions: {
        medicalConditions: defaultMedicalConditions,
        allergies: defaultAllergies
    },
    isCaregiver: false,
    familyDependents: [],
    cart: undefined,
    orderPlaced: false,
    orderNumber: '',
    patientPlans: [],
    isRemoveDisabled: false,
    isExpired: false,
    isToggleBusy: false,
    isFamilyMembersWithRx: false,
    totalOrder: undefined
};

const easyRefillSlice = createSlice({
    name: 'easy-refill',
    initialState,
    reducers: {
        setSecurityToken: (state, action: PayloadAction<{ securityToken: string }>) => {
            state.securityToken = action.payload.securityToken;
        },
        resetBearerToken: (state) => {
            state.bearerToken = initialState.bearerToken;
        },
        setExpired: (state) => {
            state.isExpired = true;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Easy Refill reducers
         */
        // Verify User
        addCase(easyRefillVerifyUserRoutine.SUCCESS, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.userVerified = payload.verificationStatus;
                draftState.epostPatientNum = payload.epostPatientNum;
            })
        );
        addCase(easyRefillVerifyUserRoutine.FAILURE, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload.messageText;
            })
        );
        // Getting user secret key hash for authentication
        addCase(easyRefillGetSecretKeyHashRoutine.SUCCESS, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.secretKeyHash = payload;
            })
        );
        addCase(easyRefillGetSecretKeyHashRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload;
            })
        );
        // authenticate user
        addCase(
            easyRefillAuthenticateUserRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = payload.bearerToken;
                    draftState.isCaregiver = payload.isCaregiver;
                })
        );
        addCase(
            easyRefillAuthenticateUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = initialState.bearerToken;
                    draftState.error.messageText = payload.messageText;
                })
        );
        // Fetching Patient data
        addCase(easyRefillGetPatientDataRoutine.TRIGGER, (state, { payload }: PayloadAction<any>) => {
            return produce(state, (draftState) => {
                if (!payload.withoutLoading) {
                    draftState.loadingPrescriptions = true;
                }
                draftState.isToggleBusy = true;
            });
        });
        addCase(
            easyRefillGetPatientDataRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ data: EasyRefillPatientDataCaregiver[] }>) =>
                produce(state, (draftState) => {
                    const patientData = state.isCaregiver
                        ? payload.data.find((patient) => patient.epostPatientNum === state.epostPatientNum)
                        : payload.data[0];

                    draftState.familyDependents = state.isCaregiver ? payload.data : [];

                    draftState.loadingPrescriptions = false;
                    draftState.autoRefillFlags = patientData?.autoRefillFlags || [];
                    draftState.rxResults = patientData?.rxResults || [];
                    draftState.firstName = patientData?.firstName || '';
                    draftState.epostPlanNum = patientData?.epostPlanNum || '';
                    draftState.planAlias = patientData?.planAlias || '';
                    draftState.prescriptionsLoaded = true;
                    draftState.isToggleBusy = false;

                    if (state.isCaregiver) {
                        // DRX-2420: Only when the patient is a Caregiver helper would be called.
                        draftState.isFamilyMembersWithRx = hasRxInFamily(payload.data);
                    }
                })
        );
        addCase(easyRefillGetPatientDataRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPrescriptions = false;
                draftState.rxResults = [];
                draftState.isToggleBusy = false;
            })
        );

        /**
         * Easy Refill Addresses Routine Reducers
         */

        addCase(easyRefillGetPatientAddressesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = true;
            })
        );
        addCase(
            easyRefillGetPatientAddressesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillAddressDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingAddress = false;

                    const filteredAddresses = payload.addresses.filter((address) => address.active === true);

                    draftState.addresses = filteredAddresses;
                })
        );
        addCase(easyRefillGetPatientAddressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
                draftState.addresses = [];
            })
        );

        addCase(
            easyRefillAddAddressesRoutine.TRIGGER,
            (state, { payload }: PayloadAction<CreateOrUpdateAddressRequest>) =>
                produce(state, (draftState) => {
                    draftState.loadingAddress = true;
                    draftState.addressToAddOrUpdate = payload;
                })
        );
        addCase(easyRefillAddAddressesRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );
        addCase(easyRefillAddAddressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );

        addCase(
            easyRefillUpdateAddressesRoutine.TRIGGER,
            (state, { payload }: PayloadAction<CreateOrUpdateAddressRequest>) =>
                produce(state, (draftState) => {
                    draftState.loadingAddress = true;
                    draftState.addressToAddOrUpdate = payload;
                })
        );
        addCase(easyRefillUpdateAddressesRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );
        addCase(easyRefillUpdateAddressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );

        /**
         * Easy Refill Payment Cards Routine Reducers
         */

        addCase(easyRefillGetPatientPaymentCardsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCard = true;
            })
        );
        addCase(
            easyRefillGetPatientPaymentCardsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillPaymentCardDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingPaymentCard = false;

                    const creditCards: PaymentCard[] =
                        Array.isArray(payload.paymentCards) && payload.paymentCards.length > 0
                            ? payload.paymentCards
                            : [];

                    const sortedCreditCards = [...creditCards].sort((cardA, cardB) => {
                        if (cardA.defaultCard === cardB.defaultCard) {
                            return cardA.cardNumber
                                .toLocaleLowerCase()
                                .localeCompare(cardB.cardNumber.toLocaleLowerCase());
                        } else {
                            return cardA.defaultCard ? -1 : 1;
                        }
                    });
                    draftState.paymentCards = sortedCreditCards;
                })
        );
        addCase(easyRefillGetPatientPaymentCardsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCard = false;
            })
        );

        addCase(easyRefillPaymetricDetailsRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.paymetricDetails = payload;
            })
        );

        addCase(
            easyRefillSubmitOrderRoutine.SUCCESS,
            (
                state,
                { payload }: PayloadAction<{ cart: StartEasyRefillOrder[]; orderPlaced: boolean; isCaregiver: boolean }>
            ) =>
                produce(state, (draftState) => {
                    const orderNumber = payload.isCaregiver
                        ? payload.cart[0].birdiOrderNumber
                        : payload.cart[0].order.orderHeader.orderNum;

                    draftState.orderPlaced = payload.orderPlaced;
                    draftState.orderNumber = orderNumber;

                    // DRX-3356: As wew are getting differente responses from the API when the user orders specific cars,
                    // we get first thew value of the current cart ordered, adn the track the total value.
                    draftState.totalOrder = payload.isCaregiver
                        ? getEasyRefillAllOrderTotal(payload.cart)
                        : payload.cart[0].order.orderTotal;
                })
        );
        addCase(easyRefillCancelOrderRoutine.SUCCESS, (state, { payload }: PayloadAction<EasyRefillResponse>) =>
            produce(state, (draftState) => {
                draftState.adjudicatedRxes = {};
                draftState.rxResults = [];
                draftState.refillOrder.rxsToRefill = [];
            })
        );
        addCase(easyRefillCancelOrderRoutine.FAILURE, (state) => produce(state, (draftState) => {}));
        addCase(easyRefillRemoveRxToRefillRoutine.SUCCESS, (state, { payload }: PayloadAction<string[]>) =>
            produce(state, (draftState) => {
                draftState.refillOrder.rxsToRefill = payload;
            })
        );
        addCase(easyRefillAddRxToRefillRoutine.SUCCESS, (state, { payload }: PayloadAction<string[]>) =>
            produce(state, (draftState) => {
                draftState.refillOrder.rxsToRefill = payload;
            })
        );
        addCase(easyRefillToggleAutoRefillRxStatusRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(
            easyRefillToggleAutoRefillRxStatusRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ rxNumber: string; autoRefillEnabled: boolean; isRenew: boolean }>) =>
                produce(state, (draftState) => {
                    const autoRefillFlags = draftState.autoRefillFlags;
                    const rxResults = draftState.rxResults;
                    const familyDependents = draftState.familyDependents;

                    const rxIndex = autoRefillFlags?.findIndex((rx) => rx.rxNumber === payload.rxNumber);

                    draftState.rxResults = rxResults?.map((rx) => {
                        if (rx.rxNumber === payload.rxNumber) {
                            return {
                                ...rx,
                                consentExpiration: payload.isRenew ? '' : rx.consentExpiration
                            };
                        } else {
                            return rx;
                        }
                    });

                    const fdIndex = familyDependents.findIndex(
                        (fd) => fd.rxResults && fd.rxResults.some((rx) => rx.rxNumber === payload.rxNumber)
                    );

                    if (fdIndex !== -1) {
                        const findIndex = familyDependents[fdIndex].rxResults.findIndex(
                            (rx) => rx.rxNumber === payload.rxNumber
                        );
                        if (findIndex !== -1) {
                            Object.assign(familyDependents[fdIndex].rxResults[findIndex], {
                                consentExpiration: payload.isRenew
                                    ? ''
                                    : familyDependents[fdIndex].rxResults[findIndex].consentExpiration
                            });
                        }
                    }

                    draftState.autoRefillFlags = autoRefillFlags?.map((autoRefillFlag, index) => {
                        if (index !== rxIndex) {
                            return autoRefillFlag;
                        }

                        return { ...autoRefillFlag, autoRefillEnabled: payload.autoRefillEnabled };
                    });
                    draftState.isBusy = false;
                })
        );
        addCase(easyRefillToggleAutoRefillRxStatusRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );
        /**
         * Health Conditions
         */
        addCase(
            easyRefillUpdateMedicalConditionsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MedicalConditions>) =>
                produce(state, (draftState) => {
                    if (draftState.healthConditions) {
                        draftState.healthConditions.medicalConditions.temporaryOther = payload.temporaryOther;
                    } else {
                        draftState.healthConditions = {
                            allergies: state.healthConditions?.allergies || defaultAllergies,
                            medicalConditions: {
                                ...payload,
                                temporaryOther: state.healthConditions?.medicalConditions.temporaryOther
                            }
                        };
                    }
                })
        );
        addCase(easyRefillUpdateAllergiesRoutine.SUCCESS, (state, { payload }: PayloadAction<Allergies>) =>
            produce(state, (draftState) => {
                if (draftState.healthConditions) {
                    draftState.healthConditions.allergies.temporaryOther = payload.temporaryOther;
                } else {
                    draftState.healthConditions = {
                        allergies: {
                            ...payload,
                            temporaryOther: state.healthConditions?.allergies.temporaryOther
                        },
                        medicalConditions: state.healthConditions?.medicalConditions || defaultMedicalConditions
                    };
                }
            })
        );
        addCase(easyRefillFetchHealthConditionsRoutine.SUCCESS, (state, { payload }: PayloadAction<HealthConditions>) =>
            produce(state, (draftState) => {
                draftState.healthConditions = {
                    medicalConditions: {
                        ...payload.medicalConditions
                    },
                    allergies: {
                        ...payload.allergies
                    }
                };
            })
        );
        /**
         * Get Cart Reducers
         */
        addCase(easyRefillGetCartRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingCart = true;
            })
        );
        addCase(easyRefillGetCartRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.loadingCart = false;
                draftState.isRemoveDisabled = false;

                if (draftState.isCaregiver) {
                    draftState.cart = payload.cart;
                } else {
                    const { ...params }: any = payload.cart[0];
                    draftState.cart = [
                        {
                            epostPatientNum: payload.epostPatientNum,
                            order: {
                                ...params.order
                            }
                        }
                    ];
                }
            })
        );
        addCase(easyRefillGetCartRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.loadingCart = false;
                draftState.cart = undefined;
                draftState.orderPlaced = initialState.orderPlaced;
                draftState.isRemoveDisabled = false;
            })
        );

        /**
         * Start Order Reducers
         */
        addCase(easyRefillStartOrderRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
                draftState.isRemoveDisabled = true;
            })
        );
        addCase(easyRefillStartOrderRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
                draftState.cart = payload;
            })
        );
        addCase(easyRefillStartOrderRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
                draftState.isRemoveDisabled = false;
            })
        );

        /**
         * Cart Update Reducers
         */
        addCase(easyRefillUpdateCartRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.cart = [{ order: payload }];
                draftState.isRemoveDisabled = true;
            })
        );
        addCase(easyRefillUpdateCartRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.isRemoveDisabled = false;
            })
        );

        /**
         * Update shipping address
         */
        addCase(easyRefillUpdateShippingRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = true;
            })
        );
        addCase(easyRefillUpdateShippingRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );
        addCase(easyRefillUpdateShippingRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );

        /**
         * Get Patient Plans
         */
        addCase(easyRefillGetPatientPlansRoutine.SUCCESS, (state, { payload }: PayloadAction<PlanResult[]>) =>
            produce(state, (draftState) => {
                draftState.patientPlans = payload;
            })
        );
        addCase(easyRefillGetPatientPlansRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.patientPlans = [];
            })
        );

        /**
         * Remove Cart Item
         */
        addCase(easyRefillCancelOrderLineRoutine.TRIGGER, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(easyRefillCancelOrderLineRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) => {
            return produce(state, (draftState) => {
                draftState.rxResults = draftState.rxResults.map((rx) => {
                    if (rx.rxNumber === payload.rxNumber) {
                        return {
                            ...rx,
                            inOrderCart: false,
                            orderStatus: 'NEW_RX',
                            webEligibilityStatus: 'ELIGIBLE',
                            rxStatus: 'Profiled'
                        };
                    }
                    return rx;
                });
                draftState.familyDependents = draftState.familyDependents.map((dependent) => {
                    const dependentRxResults = dependent.rxResults.map((rx) => {
                        if (rx.rxNumber === payload.rxNumber) {
                            return {
                                ...rx,
                                inOrderCart: false,
                                orderStatus: 'NEW_RX',
                                webEligibilityStatus: 'ELIGIBLE',
                                rxStatus: 'Profiled'
                            };
                        }
                        return rx;
                    });

                    return {
                        ...dependent,
                        rxResults: dependentRxResults
                    };
                });
                draftState.isBusy = false;
            });
        });
        addCase(easyRefillCancelOrderLineRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );
    }
});

export const easyRefillActions = easyRefillSlice.actions;

export default easyRefillSlice.reducer;
