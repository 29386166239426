import React from 'react';

const UmichLogo = () => {
    return (
        <>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 118 125"
                width="48"
                height="48"
            >
                <g>
                    <rect x="0.04" y="0.1" fill="#10284B" width="117.95" height="125"></rect>
                    <path
                        fill="#FFCC06"
                        d="M58.92,66.68L41.79,43.14v17.13h6.85v16.91H16.96V60.26h6.42V33.93h-6.42V17.02h24.89L59,40.78L76.2,17.02
                h24.89v16.91h-6.42v26.33h6.42v16.91H69.4V60.26h6.85V43.14L58.92,66.68z"
                    ></path>
                    <path
                        fill="#FFFFFF"
                        d="M84.18,108.19v-0.61c0.77-0.05,1.36-0.11,1.36-0.74c0-0.22-0.06-0.46-0.13-0.67l-0.34-1.15h-3.98L80.74,106
                c-0.08,0.24-0.14,0.47-0.14,0.7c0,0.8,0.85,0.85,1.54,0.88v0.61h-4.66v-0.61c1.18-0.05,1.57-0.29,2-1.47l3.06-8.39l-0.18-0.27
                c1.31-0.43,1.68-0.62,2.24-1.04c0.22,0.77,0.26,0.86,0.51,1.66l2.5,7.68c0.45,1.36,0.66,1.7,1.86,1.83v0.61H84.18z M83.17,99.12
                l-1.66,4.68h3.25L83.17,99.12z M48.09,105.68c-0.5,0.82-1.7,2.82-4.82,2.82c-3.4,0-5.61-2.51-5.61-5.81c0-3.06,1.87-6.07,5.69-6.07
                c2.01,0,3.07,0.85,3.72,1.36l0.78-0.7l0.06,3.42l-0.5,0.1c-0.2-0.66-0.49-1.19-1.22-1.99c-0.35-0.37-1.19-1.1-2.37-1.1
                c-2.04,0-3.65,1.66-3.65,4.59c0,2.48,1.32,4.82,3.94,4.82c1.98,0,2.72-1.1,3.31-1.95L48.09,105.68z M48.93,108.19v-0.61
                c1.28-0.11,1.3-0.38,1.3-1.47c0.02-0.4,0.02-0.8,0.02-1.2v-4.74c0-0.73,0-1.47-0.05-1.82c-0.06-0.67-0.38-0.77-1.26-0.85v-0.61
                h4.84v0.61c-0.94,0.1-1.3,0.13-1.41,0.75c-0.08,0.37-0.1,1.2-0.1,1.87v1.56c0.98,0.05,1.66,0.08,2.6,0.08
                c0.96,0,1.72-0.03,2.68-0.08v-1.9c-0.02-2.06-0.03-2.15-1.57-2.29v-0.61h4.9v0.61c-1.31,0.16-1.31,0.18-1.31,2.32v4.77
                c0,0.45,0.02,1.87,0.06,2.21c0.08,0.59,0.42,0.7,1.25,0.78v0.61h-4.9v-0.61c1.14-0.13,1.44-0.16,1.54-0.94
                c0.03-0.32,0.03-0.75,0.03-1.57V103c-0.9-0.03-1.64-0.08-2.57-0.08c-0.98,0-1.74,0.03-2.71,0.08v2.07c0.02,2.35,0.05,2.35,1.5,2.51
                v0.61H48.93z M61.81,108.19v-0.61c1.11-0.14,1.33-0.27,1.35-1.46v-1.44v-4.58c-0.02-2.35-0.02-2.42-1.35-2.61v-0.61h4.7v0.61
                c-1.33,0.13-1.33,0.19-1.33,2.61v4.56c0,0.4,0.02,1.66,0.05,2c0.08,0.69,0.4,0.85,1.28,0.91v0.61H61.81z M32.82,108.19v-0.61
                c1.1-0.14,1.33-0.27,1.34-1.46v-1.44v-4.58c-0.02-2.35-0.02-2.42-1.34-2.61v-0.61h4.69v0.61c-1.33,0.13-1.33,0.19-1.33,2.61v4.56
                c0,0.4,0.02,1.66,0.05,2c0.08,0.69,0.4,0.85,1.28,0.91v0.61H32.82z M26.77,108.19v-0.61c1.42-0.1,1.42-0.16,1.44-2.26v-5.68
                l-3.95,8.69h-0.99l-3.63-8.8l-0.08,6.64c0,1.1,0.13,1.31,1.33,1.41v0.61h-3.92v-0.61c0.91-0.05,1.26-0.18,1.33-0.94
                c0.03-0.3,0.08-1.25,0.1-1.87l0.16-4.74c0.02-0.37,0.02-0.91,0.02-1.31c0-1.12-0.22-1.2-1.23-1.22v-0.61h3.55l3.52,8.58l3.87-8.58
                h3.44v0.67c-1.34,0.08-1.36,0.22-1.34,1.98v4.66c0,0.77,0.02,1.73,0.06,2.3c0.06,0.83,0.38,0.99,1.28,1.07v0.61H26.77z
                M89.13,108.19v-0.61c0.93-0.05,1.28-0.06,1.41-0.7c0.16-0.75,0.21-7.46,0.22-8.63c-0.62-0.58-0.88-0.69-1.63-0.75v-0.61h3.39
                c0.94,1.34,1.15,1.65,2.82,3.71l3.15,3.91v-5.06c-0.02-1.79-0.06-1.89-1.86-1.95v-0.61h4.45v0.61c-1.23,0.02-1.34,0.27-1.36,2.11
                l-0.16,8.72h-0.67l-1.01-1.26c-0.51-0.64-1.02-1.28-1.54-1.9l-4.46-5.51v5.86c0,1.82,0.13,1.89,1.86,2.06v0.61H89.13z M77.98,103.2
                v0.61c-1.02,0.06-1.02,0.35-1.05,2.5v0.85c-0.88,0.54-2.13,1.33-4.35,1.33c-3.89,0-5.75-2.95-5.75-5.91c0-3.62,2.45-5.97,5.52-5.97
                c1.93,0,3.04,0.72,3.96,1.28l0.59-0.67l0.09,3.54l-0.46,0.08c-0.23-0.67-0.58-1.45-1.43-2.3c-0.37-0.37-1.1-0.84-2.23-0.84
                c-1.97,0-3.62,1.66-3.62,4.91c0,2.59,1.28,4.82,3.49,4.82c1.25,0,1.9-0.88,1.96-0.94c0.2-0.3,0.25-0.58,0.23-1.07
                c-0.05-1.44-0.24-1.47-1.5-1.6v-0.61H77.98z"
                    ></path>
                    <path
                        fill="#FFFFFF"
                        d="M95.05,89.6c0,2.58-1.76,3.94-3.74,3.94c-2.17,0-3.87-1.57-3.87-3.95c0-2.04,1.37-3.88,3.77-3.87
                C93.48,85.73,95.05,87.33,95.05,89.6z M91.32,92.85c0.42-0.01,1.02-0.22,1.48-0.92c0.53-0.81,0.64-1.55,0.61-2.53
                c-0.03-0.96-0.3-1.79-0.85-2.38c-0.24-0.26-0.71-0.63-1.34-0.62c-0.62,0-1.08,0.39-1.32,0.66c-0.53,0.6-0.77,1.44-0.77,2.4
                c0,0.97,0.13,1.71,0.68,2.51C90.3,92.66,90.9,92.85,91.32,92.85z M95.42,92.93v0.41h3.09v-0.41c-0.77-0.07-0.87-0.15-0.89-0.96
                l0.01-2.13h0.83c0.84,0,1.28,0.08,1.33,0.91h0.36v-2.46h-0.36c-0.04,0.8-0.26,0.88-1.17,0.88h-0.98l0-2.59
                c0.26-0.03,0.41-0.04,0.89-0.04c1.46,0,1.93-0.01,2.13,1.16h0.37l-0.12-1.83h-5.48v0.4c0.54,0.05,0.77,0.09,0.85,0.43
                c0.05,0.23,0.06,1.06,0.06,1.35l0,2.62c0,0.2-0.01,1.1-0.02,1.28C96.28,92.78,96.19,92.86,95.42,92.93z M34.28,93.29v-0.4
                c0.73-0.1,0.88-0.18,0.89-0.96v-0.95v-3.02c-0.01-1.56-0.01-1.6-0.89-1.73v-0.4h3.1v0.4c-0.88,0.09-0.88,0.13-0.88,1.73v3.01
                c0,0.26,0.01,1.1,0.03,1.32c0.05,0.45,0.26,0.56,0.85,0.6v0.4H34.28z M65.26,93.29v-0.4c0.73-0.1,0.88-0.18,0.89-0.96v-0.95v-3.02
                c-0.01-1.56-0.01-1.6-0.89-1.73v-0.4h3.1v0.4c-0.88,0.09-0.88,0.13-0.88,1.73v3.01c0,0.26,0.01,1.1,0.03,1.32
                c0.05,0.45,0.26,0.56,0.85,0.6v0.4H65.26z M25.57,93.29v-0.4c0.61-0.03,0.85-0.04,0.93-0.47c0.11-0.5,0.14-4.93,0.15-5.7
                c-0.41-0.38-0.58-0.45-1.08-0.5v-0.4h2.24c0.62,0.89,0.76,1.09,1.86,2.45l2.08,2.58v-3.34c-0.01-1.19-0.04-1.25-1.23-1.29v-0.4
                h2.94v0.4c-0.81,0.01-0.89,0.18-0.9,1.4l-0.11,5.77h-0.44l-0.67-0.84c-0.34-0.42-0.68-0.85-1.02-1.26l-2.95-3.64v3.87
                c0,1.21,0.08,1.25,1.23,1.36v0.4H25.57z M59.46,90.96l0.38-0.04c0.41,1.22,1.1,1.87,2.22,1.87c0.8,0,1.49-0.34,1.49-1.14
                c0-0.85-0.82-1.18-1.18-1.33c-0.3-0.12-0.61-0.21-0.91-0.33c-0.61-0.22-1.77-0.7-1.77-2.15c0-1.4,1.1-2.18,2.38-2.18
                c0.5,0,1.08,0.1,1.72,0.51l0.45-0.33l0.24,2.22l-0.37,0.07c-0.13-0.49-0.22-0.71-0.52-1.11c-0.36-0.47-0.87-0.68-1.46-0.68
                c-0.79,0-1.26,0.38-1.26,0.97c0,0.89,0.88,1.19,1.72,1.46c0.42,0.14,0.77,0.25,1.08,0.43c0.59,0.35,1.08,1.01,1.08,1.9
                c0,1.29-1.06,2.36-2.64,2.36c-1.02,0-1.64-0.4-1.94-0.59l-0.62-0.45L59.46,90.96z M70.98,93.29v-0.32
                c0.69-0.01,0.88-0.26,0.88-0.86v-5.5h-0.93c-0.85,0-1.41-0.03-1.47,1.3h-0.39l0.06-2.09h6.79l0.06,2.09H75.6
                c-0.06-1.33-0.62-1.3-1.47-1.3H73.2v5.5c0,0.59,0.19,0.85,0.88,0.86l0,0.32H70.98z M20.25,85.82v0.4c-0.99,0.07-0.99,0.11-1,1.69
                v1.76c0,0.91,0.02,1.69,0.5,2.39c0.38,0.56,1.03,0.63,1.36,0.63c1.07,0,1.66-0.56,1.91-1.43c0.21-0.7,0.22-2.01,0.22-2.88
                c0-0.28,0-1.16-0.01-1.41c-0.04-0.68-0.39-0.7-1.06-0.75v-0.4h2.85v0.4c-0.7-0.01-0.85,0.15-0.89,0.89
                c-0.01,0.18-0.1,2.25-0.11,2.57c-0.02,0.57-0.05,1.13-0.18,1.67c-0.51,2.01-2.47,2.11-2.98,2.11c-0.41,0-1.26-0.06-1.96-0.53
                c-1.11-0.75-1.12-1.87-1.12-3.08v-1.69c-0.01-0.8-0.01-1.16-0.03-1.35c-0.06-0.47-0.3-0.56-0.86-0.59v-0.4H20.25z M45.56,85.82v0.4
                c-0.72,0.11-0.81,0.31-1.43,1.79l-2.27,5.43h-0.4l-2.04-5.51c-0.51-1.42-0.57-1.59-1.32-1.7v-0.4h3.54v0.4
                c-0.6,0.02-0.99,0.04-0.99,0.48c0,0.21,0.05,0.39,0.25,1.02l1.3,3.8l1.52-3.92c0.1-0.26,0.22-0.6,0.22-0.79
                c0-0.54-0.53-0.56-0.99-0.57v-0.4H45.56z M78.68,93.29v-0.4c0.58-0.06,0.8-0.09,0.95-0.23c0.17-0.17,0.17-0.36,0.18-1.17v-1.31
                l-1.99-3.08c-0.54-0.8-0.55-0.82-1.27-0.87v-0.41h3.89v0.41c-0.78,0.02-1.13,0.04-1.13,0.4c0,0.2,0.14,0.43,0.33,0.75l1.14,1.87
                l1.06-1.86c0.19-0.37,0.3-0.59,0.3-0.76c0-0.36-0.31-0.37-0.83-0.4v-0.41h2.92v0.41c-0.75,0.02-0.97,0.24-1.83,1.64l-1.16,1.95
                v1.56c0,1.35,0.01,1.46,1.12,1.52v0.4H78.68z M51.57,93.29c0-0.54,0.1-1.43,0.16-1.94h-0.36c-0.25,1.33-1,1.27-2.08,1.27
                c-0.99,0-1.19-0.26-1.19-0.75v-2.09h0.8c0.92,0,1.18,0.08,1.18,0.92h0.36v-2.47h-0.36c-0.07,0.67-0.21,0.88-1.17,0.88h-0.81v-2.63
                h0.92c1.27,0,1.72,0.1,1.89,1.11h0.34l-0.04-1.78h-5.32v0.4c0.54,0.05,0.77,0.09,0.85,0.43c0.05,0.23,0.06,1.06,0.06,1.35v2.63
                c0,0.2-0.01,1.1-0.02,1.28c-0.02,0.82-0.12,0.89-0.89,0.96v0.41H51.57z M54.66,89.29c0.2,0.04,0.49,0.07,0.7,0.07
                c0.85,0,1.49-0.66,1.52-1.26c0.05-0.87-0.39-1.62-1.77-1.62c-0.19,0-0.31,0.01-0.45,0.04V89.29z M54.65,90.91
                c0.01,1.71,0.01,1.94,0.91,1.96v0.41h-3.12v-0.41c0.8-0.06,0.89-0.19,0.9-0.99c0.01-0.31,0.01-0.65,0.01-0.96v-2.7
                c0-0.9-0.01-1.22-0.04-1.46c-0.05-0.41-0.25-0.49-0.87-0.54v-0.4l3.38,0.01c1.84,0,2.42,0.88,2.42,1.91c0,0.72-0.44,1.66-1.47,1.97
                l1.32,2.3c0.48,0.88,0.73,1.08,1.33,1.16v0.25c-0.16,0.01-0.32,0.02-0.49,0.02c-1.41,0-1.99-0.49-2.58-1.74l-0.41-0.88
                c-0.44-0.94-0.59-0.88-1.3-0.89L54.65,90.91z"
                    ></path>
                </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="48" viewBox="0 0 20 48">
                <g>
                    <path
                        fill="none"
                        stroke="rgb(172,185,200)"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        strokeWidth="1"
                        d="M10 2.35231505v50"
                    ></path>
                </g>
            </svg>
        </>
    );
};

export default UmichLogo;
