import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import Link from 'ui-kit/link/link';
import Logo from 'ui-kit/logo/logo';
import PillLogo from 'ui-kit/logo/pill-logo/pill-logo';
import UmichLogo from 'ui-kit/logo/umich-logo/umich-logo';

import {
    IntraPageMenuItemContentValue,
    IntraPageMenuItemProps
} from 'components/navigation/intra-page-menu/intra-page-menu.props';
import { MenuProps, NavMenuParent, NavPath } from 'components/navigation/navigation.props';

import { useGlobalLink } from 'hooks/useGlobalLink';
import useMediaQuery from 'hooks/useMediaQuery';
import useUmichSite from 'hooks/useUmichSite';

import './desktop-menu.style.scss';

const DesktopMenu = ({
    isScrolled,
    rightMenuPaths,
    userName,
    profileMenu,
    isLoggedIn,
    cartItemsTotal,
    leftMenuPaths = []
}: MenuProps) => {
    const [rightNavPaths, setRightNavPaths] = useState<NavPath[] | undefined>();
    const [leftNavPaths, setLeftNavPaths] = useState<NavMenuParent[]>();
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const longMenuTest = useMediaQuery('(max-width: 1128px)'); // DRX-1254: Use pill logo to prevent wrapping menus (need to allow for co-branding logo)
    const isUmichSite = useUmichSite();
    const { t } = useTranslation();

    const setNavButtonVariant = (buttonVariant: 'primary' | 'outline' | undefined) => {
        if (!buttonVariant) return 'nav-button-outline';
        if (buttonVariant === 'primary') return 'nav-button-primary';

        return 'nav-button-outline';
    };

    useEffect(() => {
        setRightNavPaths(rightMenuPaths);
    }, [rightMenuPaths, isLoggedIn]);

    useEffect(() => {
        const menuItems: NavMenuParent[] = leftMenuPaths
            .filter((navItem) => !navItem.menu_parent)
            .map((item) => {
                const menuItem: NavMenuParent = { ...item };
                menuItem.children = leftMenuPaths.filter((i) => i.menu_parent === item.id);
                return menuItem;
            });
        setLeftNavPaths(menuItems);
    }, [leftMenuPaths]);

    useEffect(() => {
        setCartTotal(cartItemsTotal);
    }, [cartItemsTotal]);

    const globalLink = useGlobalLink();
    const { trackFormAbandonment, handleSignOut } = globalLink;

    return (
        <Container fluid="xl" className="desktop-menu d-none d-lg-flex">
            <Navbar.Brand>
                {isUmichSite && (
                    <Link
                        to="https://umich.birdirx.com/"
                        label={<UmichLogo />}
                        variant="site"
                        dataGALocation="UmichHeaderLogo"
                        ariaLabel="University of Michigan Homepage"
                        className="desktop-menu-header--umich-logo"
                    />
                )}
                <Link
                    onClick={trackFormAbandonment}
                    to={isLoggedIn ? '/secure/medicine-cabinet' : '/'}
                    label={
                        <>
                            <div className="co-brand-logo d-flex"></div>
                            {isScrolled || (rightNavPaths && rightNavPaths?.length > 5 && longMenuTest) ? (
                                <PillLogo variant="primary" />
                            ) : (
                                <Logo variant="primary" />
                            )}
                        </>
                    }
                    variant="site"
                    dataGALocation="HeaderLogo"
                    ariaLabel="Birdi Homepage"
                />
            </Navbar.Brand>
            {leftNavPaths && leftNavPaths.length > 0 && (
                <Navbar.Collapse id="site-navbar-paths">
                    <Nav className="left-main-menu">
                        {leftNavPaths.map(({ label, id, children }) => (
                            <DropdownButton id="dropdown-basic-button" title={label} key={`left-menu-item-${id}`}>
                                {children && (
                                    <>
                                        {children
                                            .filter((f) => !f.isMobile)
                                            .map(({ label, link, id, isButton }) => (
                                                <React.Fragment key={`left-menu-item-child-${id}`}>
                                                    {link ? (
                                                        <>
                                                            <Dropdown.Item
                                                                as={Link}
                                                                to={link.url}
                                                                label={label}
                                                                data-tagtype="anchorlink"
                                                                dataGALocation="HeaderLeftNav"
                                                                onClick={trackFormAbandonment}
                                                                className={isButton ? 'btn' : ''}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Dropdown.ItemText>{label}</Dropdown.ItemText>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                    </>
                                )}
                            </DropdownButton>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            )}

            <Navbar.Collapse id="site-navbar-paths" className="justify-content-end">
                <Nav>
                    {rightNavPaths &&
                        rightNavPaths
                            .filter((f) => !f.isMobile)
                            .map(({ link, label, isButton, buttonVariant }: NavPath) => (
                                <React.Fragment key={`site-nav-${label}`}>
                                    {link && (
                                        <Link
                                            onClick={trackFormAbandonment}
                                            to={link.url}
                                            label={label}
                                            variant={
                                                Boolean(isButton) === true ? setNavButtonVariant(buttonVariant) : 'nav'
                                            }
                                            dataGALocation="HeaderNav"
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                    {profileMenu && (
                        <>
                            <div className="menu-links-profile">
                                <DropdownButton id="dropdown-basic-button" title={userName}>
                                    {profileMenu.map(
                                        ({
                                            key,
                                            value,
                                            label,
                                            isChild
                                        }: IntraPageMenuItemProps<IntraPageMenuItemContentValue>) => (
                                            <React.Fragment key={`profile-item-${key}`}>
                                                {value && (
                                                    <Dropdown.Item
                                                        as={Link}
                                                        to={value}
                                                        label={label}
                                                        data-tagtype="anchorlink"
                                                        dataGALocation="HeaderNav"
                                                        className={isChild ? 'dropdown-item-child' : ''}
                                                        onClick={trackFormAbandonment}
                                                    />
                                                )}
                                                {!value && <Dropdown.ItemText>{label}</Dropdown.ItemText>}
                                            </React.Fragment>
                                        )
                                    )}
                                    <Dropdown.Item
                                        className="menu-links-profile__logout"
                                        key={'profile-item-logout'}
                                        onClick={handleSignOut}
                                        data-tagtype="anchorlink"
                                        data-ga-location="HeaderNav"
                                    >
                                        {t('menus.intraPage.profile.signOut')}
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <Link
                                to={'/secure/cart/review/'}
                                label={<CartIcon itemCount={cartTotal} className={'border-white'} />}
                                variant={'nav'}
                                className={'no-active-state'}
                                dataGALocation="HeaderNav"
                            />
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Container>
    );
};

export default DesktopMenu;
