import React, {ReactElement, useState, useMemo} from 'react';
import Alert from 'react-bootstrap/Alert';
import {Row, Col, Container} from 'react-bootstrap';
import classNames from 'classnames';
import {InfoProps} from './info-banner.props';
import {useLocation} from '@reach/router';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import './info-banner.style.scss';
// import { Link } from 'gatsby';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import StarIcon from 'ui-kit/icons/star/star-icon';

const infoDismissBanner = () => {
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('dismissPermanentInfoBanner', 'true');
    }


};

const InfoHasBeenDismissed = () => {
    if (typeof sessionStorage !== 'undefined') {
        const InfoHasBeenDismissed = sessionStorage.getItem('dismissPermanentInfoBanner') != null ? true : false;
        return InfoHasBeenDismissed;
    }
};

const InfoBanner = ({   className,
                        dismissible,
                        icon,
                        permanent
                    }: InfoProps):
    ReactElement => {
    const [show, setShow] = useState(true);
    const classes = classNames('info-banner mb-0 d-print-none', className);
    let renderIcon = null;
    switch (icon) {
        case 'alert': {
            renderIcon = <AlertIcon/>;
            break;
        }
        case 'star': {
            renderIcon = <StarIcon/>;
            break;
        }
    }

    const location = useLocation();

    const displayBanner = useMemo(() => location.pathname === '/', [location]);

    const {t} = useTranslation();

    if (show && (permanent ? !InfoHasBeenDismissed() : true)) {
        return (
            <div className={displayBanner ? '' : 'd-none'}>
                <Alert
                    className={classes, 'info-banner-container'}
                    onClose={() => {
                        setShow(false);
                        if (permanent) {
                            infoDismissBanner();
                        }
                    }}
                    dismissible={dismissible}
                    data-ga-location="Info Banner"
                >
                    <Container>
                        <Row>

                            <p className="info-banner">
                                <div className="info-banner-container__wrapper">
                                    <div
                                        className="info-banner-container__container">
                                        <div
                                            className="info-banner-container__left">
                                            <img
                                                className={classes, 'info-banner-container__left__image'}
                                                alt="birdi / elixir logos"
                                                data-entity-type="file"
                                                data-entity-uuid="cfcd93a5-068d-4112-b6b6-363a81bf834f"
                                                src="https://www.birdirx.com/sites/default/files/2024-02/birdi_elixir.png"
                                            /></div>

                                        <div
                                            className="info-banner-container__right">
                                            <p>{t('components.infoBanner.messageOne')}</p>
                                            {/*<p>{t('components.infoBanner.messageTwo')}</p>*/}
                                        </div>
                                    </div>
                                </div>
                            </p>
                        </Row>
                    </Container>
                </Alert>
            </div>
        );
    }
    return <></>;
};

export default InfoBanner;
