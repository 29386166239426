import React, { ReactElement } from 'react';

import Select from 'ui-kit/select/select';

import { FormSelectProps } from 'ui-kit/form-select/form-select.props';
import { SelectChangeEvent } from 'ui-kit/select/select.props';
import { SelectOptionValue } from 'types/select';

const FormSelect = <T extends SelectOptionValue>(props: FormSelectProps<T>): ReactElement => {
    const { form, field, onSelectionChanged, singleOptionComponent, options, value, defaultValue, ...rest } = props;

    const handleChange = ({ key, option }: SelectChangeEvent<T>): void => {
        const { value } = option;
        if (form && form.setFieldValue) form.setFieldValue(field.name, value);
        if (onSelectionChanged) onSelectionChanged({ key, option }, form);
    };

    // const hasDefaultValue = defaultValue && defaultValue !== value;
    const hasSingleOption = Array.isArray(options) && options.length === 1;
    const hasSingleOptionComponent = React.isValidElement(singleOptionComponent);

    if (hasSingleOption && hasSingleOptionComponent) {
        return singleOptionComponent;
    } else {
        return (
            <Select<T>
                onChange={handleChange}
                {...rest}
                value={defaultValue || value}
                options={options}
                name={field.name}
            />
        );
    }
};

export default FormSelect;
