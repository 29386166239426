// General
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Hooks
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
// Ui-kit
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';

import EmptyAlertBox from 'components/medicine-cabinet-cart/empty-alert-box/empty-alert-box';

import { accountIsMembershipSelector } from 'state/account/account.selectors';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { medicineCabinetIsBusySelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
// States
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

import { formatPrice } from 'schema/price.schema';

// Interfaces and types
import { ExtendedRefillRxs } from 'types/order-prescription';

// Utils
import { hasAdjudicatedPrice } from 'util/cart';
import { noop } from 'util/function';
import { getPhoneNumber } from 'util/globalVariables';
import { safeParseFloat } from 'util/number';

// Components
import AutoRefillBadge from '../auto-refill-badge/auto-refill-badge';
// Styles
import './prescription-item.style.scss';

// Main component interface
export interface PrescriptionItemProps {
    isLoading?: boolean;
    isCaregiver?: boolean;
    patientName?: string;
    onRemovePrescriptionClick?: ButtonProps['onClick'];
    prescription?: ExtendedRefillRxs;
    currentPrescription?: PrescriptionObjectPayload;
    isInsuranceAccount?: boolean;
    discountPrice?: DrugWithDiscountPrice;
}

// Main component
const PrescriptionItem: React.FC<PrescriptionItemProps> = (props) => {
    // General
    const { onRemovePrescriptionClick = noop, currentPrescription, prescription, isInsuranceAccount } = props;

    // Hooks
    const { t } = useTranslation();

    // States
    const [isBusy, setIsBusy] = useState<boolean>(false);

    // Selectors
    const prescriptionsIsBusySelector = useSelector(medicineCabinetIsBusySelector);
    const isMembership = useSelector(accountIsMembershipSelector);

    useEffect(() => {
        setIsBusy(prescriptionsIsBusySelector);
    }, [prescriptionsIsBusySelector]);

    const formatBirdiSavings = React.useCallback(
        (item: ExtendedRefillRxs) => {
            if (item) {
                const normalPrice = safeParseFloat(item.awpPrice);
                const birdiPrice = safeParseFloat(item.birdiPrice);

                if (birdiPrice < normalPrice) {
                    const savingsAsDollar = normalPrice - birdiPrice;
                    return t('components.medicineCabinetCart.prescriptionInfo.save', {
                        save: formatPrice(savingsAsDollar)
                    });
                }
            } else {
                return '';
            }
        },
        [t]
    );

    const validateDiscountVisibility = React.useCallback((item: ExtendedRefillRxs) => {
        if (!item) {
            return undefined;
        }

        const normalPrice = safeParseFloat(item.awpPrice);
        const birdiPrice = safeParseFloat(item.birdiPrice);

        return normalPrice > birdiPrice ? normalPrice - birdiPrice : undefined;
    }, []);

    return (
        <div className="pb-3">
            {currentPrescription && prescription && (
                <>
                    <section className="prescription-item-wrapper">
                        {props.isCaregiver ? (
                            <h3 className="prescription-item-patient-name font-weight-bolder">{props.patientName}</h3>
                        ) : (
                            <></>
                        )}

                        {/* First Section */}
                        <div className="prescription-item">
                            {/* Drug price */}
                            <div className="prescription-item-info text-ebony-clay">
                                {/* Product Name */}

                                {currentPrescription?.dispensedProductName}

                                {/* When the price is not adjudicted */}

                                {(!isMembership || !hasAdjudicatedPrice(prescription, currentPrescription)) &&
                                !prescription?.hasKnownPrice &&
                                currentPrescription?.dispensedProductName ? (
                                    <sup>*</sup>
                                ) : (
                                    ''
                                )}

                                {/* Prescription number */}

                                <div className="prescription-item-rx mt-2">
                                    {t('components.medicineCabinetCart.prescriptionInfo.rx', {
                                        number: prescription?.rxNumber
                                    })}
                                </div>

                                {/* Qunatities */}
                                <div className="prescription-item-quantities d-flex align-items-center flex-wrap">
                                    {currentPrescription?.fillQuantity && (
                                        <div>
                                            {t('pages.cart.quantity', {
                                                fillQuantity: currentPrescription.fillQuantity
                                            })}
                                        </div>
                                    )}

                                    {(currentPrescription?.dispensedProductStrength ||
                                        currentPrescription?.dispensedProductUnitOfMeasure) && (
                                        <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                                    )}

                                    {(currentPrescription?.dispensedProductStrength ||
                                        currentPrescription?.dispensedProductUnitOfMeasure) && (
                                        <div>
                                            {currentPrescription.dispensedProductStrength}
                                            {currentPrescription.dispensedProductUnitOfMeasure}
                                        </div>
                                    )}

                                    {currentPrescription?.dispensedProductDosageForm && (
                                        <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                                    )}

                                    {currentPrescription?.dispensedProductDosageForm && (
                                        <div className="prescription-item-dosage">
                                            {currentPrescription.dispensedProductDosageForm}
                                        </div>
                                    )}
                                </div>

                                {currentPrescription?.autoRefillEnabled ? (
                                    <div className="mt-2">
                                        <AutoRefillBadge
                                            text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div className="left-n1 ml-lg-auto text-right prescription-item-price">
                                {(isMembership || hasAdjudicatedPrice(prescription, currentPrescription)) &&
                                prescription?.hasKnownPrice ? (
                                    <>
                                        {validateDiscountVisibility(prescription) && (
                                            <div className="prescription-item-price-line">${prescription.awpPrice}</div>
                                        )}
                                        <div className="prescription-item-price-total">
                                            {formatPrice(
                                                prescription.isUsingBirdiPrice
                                                    ? prescription.birdiPrice
                                                    : prescription.patientCopay
                                            )}
                                        </div>
                                        {/* Est.Copay - just for insurance patients */}
                                        {prescription.insurancePayment !== null &&
                                            prescription.insurancePayment > 0 && (
                                                <div className="prescription-item-price-copay">
                                                    {t('components.medicineCabinetCart.prescriptionInfo.copay')}
                                                </div>
                                            )}
                                    </>
                                ) : (
                                    <div className="prescription-item-no-price">
                                        {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}
                                    </div>
                                )}

                                {(hasAdjudicatedPrice(prescription, currentPrescription) || isMembership) &&
                                    prescription?.hasKnownPrice &&
                                    !isInsuranceAccount && (
                                        <div>
                                            {/* Birdi price */}
                                            <div className="d-flex justify-content-end prescription-item-birdi-price">
                                                <BirdiPriceIcon />
                                                <div>
                                                    {t('components.medicineCabinetCart.prescriptionInfo.birdiPrice')}
                                                </div>
                                            </div>

                                            {/* Disccount price - Just for insurance patients */}
                                            {validateDiscountVisibility(prescription) && (
                                                <div className="d-flex text-right mt-1 prescription-item-saving justify-content-end">
                                                    {formatBirdiSavings(prescription)}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                {/* Remove Button */}
                                {isBusy ? (
                                    <SpinnerInline />
                                ) : (
                                    <Button
                                        type="button"
                                        variant="text"
                                        className="px-0 pt-0 no-min-width text-cerulean left-n1 ml-lg-auto mt-2 font-weight-bold text-right"
                                        label={t('shipping.removeAddress')}
                                        onClick={onRemovePrescriptionClick}
                                        dataGAFormName="addMorePrescriptions"
                                    />
                                )}
                            </div>
                        </div>
                    </section>

                    {prescription?.showDisclaimer && prescription?.disclaimerTranslationKey && (
                        <Row className="d-flex justify-content-between pt-4">
                            <EmptyAlertBox
                                text={t(prescription?.disclaimerTranslationKey, {
                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                })}
                            />
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default PrescriptionItem;
