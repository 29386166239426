import React, {FC, ReactElement} from 'react';

import {getImage, GatsbyImage} from 'gatsby-plugin-image';
import {Container, Row, Col} from 'react-bootstrap';

import withBackgroundImage from 'hoc/withBackgroundImage';

import Link from 'ui-kit/link/link';

import {HeroProps} from 'components/hero/hero.props';

import './hero.style.scss';

const Hero: FC<HeroProps> = ({
                                 backgroundColor,
                                 backgroundImage,
                                 eyebrowText,
                                 eyebrowColor = 'light',
                                 image,
                                 imagePosition,
                                 style,
                                 imageAltText,
                                 linkLabel,
                                 linkTo,
                                 size = 'large',
                                 text,
                                 title,
                                 sectionIndex
                             }: HeroProps): ReactElement => {
    return (
        <Container
            fluid
            className={`hero ${backgroundColor ?? ''} ${size ?? ''} ${imagePosition ?? ''} ${backgroundImage ? 'has-background-image' : ''} ${style}`}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
            data-ga-title={title}
        >
            <Row>
                <Col xs="12" md="12" lg="6"
                     className={`d-flex align-items-center`}>
                    <div className="hero-content">
                        {eyebrowText && (
                            <div
                                className={`h5 hero-eyebrow-text ${eyebrowColor ? eyebrowColor : ''}`}>
                                {eyebrowText}
                            </div>
                        )}
                        {sectionIndex === 0 &&
                            <h1 className="hero-title">{title}</h1>}
                        {sectionIndex > 0 &&
                            <h2 className="hero-title">{title}</h2>}
                        {sectionIndex === 0 &&
                            <div className="h3 hero-text">{text}</div>}
                        {sectionIndex > 0 &&
                            <div className="h4 hero-title">{text}</div>}
                        {
                            <Link
                                to={linkTo}
                                label={linkLabel}
                                variant="cta-button"
                                dataGALocation={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
                            />
                        }
                    </div>
                </Col>

                {image && (
                    <Col xs="12" md="12" lg="6"
                         className={`d-flex hero-image ${imagePosition ?? ''}`}>
                        <div className="hero-image-container">
                            <GatsbyImage image={getImage(image)}
                                         alt={imageAltText}/>
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export const HeroWithBackgroundImage = withBackgroundImage(Hero, 'heroBackground');
export default Hero;
