import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

import 'components/alert-banner/alert-banner.style.scss';

import storageHelper from 'util/storageHelper';

import './cookie-banner.style.scss';

const hasBeenDismissed = () => storageHelper.cookies.getCookieAccepted();

const CookieBanner = (): ReactElement => {
    const [dismissed, setDismissed] = useState(hasBeenDismissed());
    const [isEnd, setIsEnd] = useState(false);

    const { t } = useTranslation();

    // DRX-3471: As we got some specific scenarios when the chatbot could overlap
    // the cookie banner, we decided to refactor the logic and handle the display of
    // the chatbot button based on the position of the scroll, so if the user has an
    // AdBlocker, the chatbot button will still show as expected.
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            if (scrollTop + windowHeight >= documentHeight) {
                setIsEnd(true);
            } else {
                setIsEnd(false);
            }
        };

        document.addEventListener('scroll', handleScroll);

        // Interval to check if the cookie banner has been dismissed
        const intervalId = setInterval(() => {
            const isDismissed = hasBeenDismissed();
            if (isDismissed !== dismissed) {
                setDismissed(isDismissed);
            }
        }, 100);

        // Cleanup function to remove event listener and interval when component unmounts
        return () => {
            document.removeEventListener('scroll', handleScroll);
            clearInterval(intervalId);
        };
    }, [dismissed]);

    // Effect to handle class updates based on the scroll position and dismissal status
    useEffect(() => {
        const elements = document.body.getElementsByClassName('robinChatbot');

        Array.from(elements).forEach((element) => {
            if (isEnd) {
                element.classList.add('isEnd');
            } else {
                element.classList.remove('isEnd');
            }
        });
    }, [dismissed, isEnd]);

    const handleClose = () => {
        storageHelper.cookies.setCookieAccepted();
    };

    return (
        <>
            {!dismissed && (
                <Alert
                    className="alert-banner cookie-banner mb-0"
                    onClose={handleClose}
                    variant={'dark'}
                    dismissible
                    data-ga-location="Cookie Banner"
                    data-ga-type={'dark'}
                >
                    <Container>
                        <Row>
                            <Col
                                xs={{ span: 8, offset: 2 }}
                                sm={{ span: 8, offset: 2 }}
                                md={{ span: 12, offset: 0 }}
                                lg="12"
                            >
                                <p className="alert-message">
                                    <CircleInfo />
                                    {t('components.cookie.byUsingBirdi')}{' '}
                                    <Alert.Link href="/website-terms-of-use">
                                        {t('components.cookie.termsOfUse')}
                                    </Alert.Link>{' '}
                                    {t('components.cookie.and')}{' '}
                                    <Alert.Link href="/website-privacy-policy">
                                        {t('components.cookie.webPrivacy')}
                                    </Alert.Link>{' '}
                                    {t('components.cookie.includingCollection')}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Alert>
            )}
        </>
    );
};

export default CookieBanner;
