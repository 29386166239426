import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from 'react-bootstrap';

// Components & UI
import Button from 'ui-kit/button/button';
import Spinner from 'ui-kit/spinner/spinner';
import { ShippingAddressesProps } from 'components/shipping-addresses-v2/shipping-addresses.props';
import AddressCard from 'components/shipping-addresses-v2/address-card/address-card.component';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import AddAddressForm, {
    AddressFormSchema,
    AddressVerificationFailureModalContent
} from 'components/add-address-form/AddAddressForm';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

// Hooks
import { useAddressVerification } from 'hooks/useAddressVerification';

// Shipping Address & Services
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { ProfileObjectAddressPayload } from 'state/account/account.services';
import { AddressParts } from 'state/usps/usps.reducers';
import {
    accountProfileAddressesSelector,
    accountProfileSelector,
    accountAutoRefillEligibleSelector
} from 'state/account/account.selectors';
import {
    accountAddAddressToProfileRoutine,
    accountSetPrimaryAddressRoutine,
    accountUpdateAddressToProfileRoutine
} from 'state/account/account.routines';
import { AddressCardProps } from './address-card/address-card.props';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

import './shipping-addresses.style.scss';
import AutoRefillBody from 'components/auto-refill-body/auto-refill-body';
// Util
import storageHelper from 'util/storageHelper';
import { validateAutoRefillModalDisplay } from 'util/autorefill';

const ShippingAddresses: FC<ShippingAddressesProps> = ({
    addressData,
    onSetAsShipping,
    isProfile,
    showLabels = true,
    addNewAddressButtonLabel
}: ShippingAddressesProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isAddressBusy, setIsAddressBusy] = useState(false);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
    const profileObject = useSelector(accountProfileSelector);
    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();
    const sortDefaultAddresses = useMemo(() => {
        // Add defaultAddress boolean to first address automatically to be addressed in DRX-115
        let addressesToSort = [...addressData];

        return addressesToSort.sort((a, b) => Number(b.defaultAddress) - Number(a.defaultAddress));
    }, [addressData]);

    const handleAddAddressFormCancel = () => {
        dispatch(closeModal({}));
    };

    const closeModalHandler = () => {
        dispatch(closeModal({}));
        storageHelper.local.setAutoRefillToggleFlag();
    };

    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.addAddressModal.title')}
                                handleFormCancel={handleAddAddressFormCancel}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                                showSetAsDefault={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleAddAddressFormSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                setIsAddressBusy(true);
                dispatch(
                    accountAddAddressToProfileRoutine.trigger({
                        ...values,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onSuccess: () => {
                            setIsAddressBusy(false);
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'UpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: ({ error }) => {
                setIsAddressBusy(false);
                if (values.onFailure && error && !error.message) {
                    values.onFailure(error);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('modals.addressVerificationFailure.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        handleAddNewAddressClick();
                                    },
                                    dataGALocation: 'AddressNotVerified'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    const showAutoRefillModal = useCallback(
        (address: AddressCardProps) => {
            // Calculating address based on profile to get old state
            // and knowing if the user in the past had a CA address
            // before applying the address default change.
            const isCAResident = profileObject?.addresses.find(
                (address) => address.state === 'CA' && address.defaultShip
            );
            if (validateAutoRefillModalDisplay(isAutoRefillEligible, !!isCAResident, address.state)) {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'scroll-modal',
                        onClose: closeModalHandler,
                        bodyContent: (
                            <BirdiModalContent
                                icon={'none'}
                                title={t(`pages.autoRefill.userVerification.title`)}
                                body={<AutoRefillBody isModal onClose={closeModalHandler} />}
                            />
                        ),
                        ctas: []
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isAutoRefillEligible, profileObject]
    );

    const handleShippingAddressChange = (address: AddressCardProps) => {
        setIsAddressBusy(true);
        dispatch(
            accountSetPrimaryAddressRoutine({
                ...address,
                onSuccess: () => {
                    setIsAddressBusy(false);
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: isProfile ? (
                                <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                            ) : (
                                <BirdiModalContent
                                    icon={'success'}
                                    title={t('modals.updateProfile.title')}
                                    body={t('modals.updateCart.defaultAddressUpdated')}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        showAutoRefillModal(address);
                                    },
                                    dataGALocation: t('modals.updateProfile.title')
                                }
                            ],
                            onClose: () => showAutoRefillModal(address)
                        })
                    );
                },
                onFailure: () => {
                    setIsAddressBusy(false);
                    dispatch(
                        openModal({
                            showClose: true,
                            size: 'lg',
                            type: 'danger',
                            headerContent: (
                                <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                            ),
                            bodyContent: (
                                <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'UpdateProfileError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleEditAddressClick = (address: AddressCardProps) => {
        const editAddress: AddressFormSchema =
            address.zipcodeFour === ('' || null)
                ? { ...address, addressTypeDesc: address.addressType }
                : {
                      ...address,
                      zipcode: `${address.zipcode}-${address.zipcodeFour}`,
                      addressTypeDesc: address.addressType
                  };
        const editAddressIndex = profileAddresses.findIndex(
            (profileAddress) => profileAddress.addressSeqNum === address.addressSeqNum
        );

        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.editAddressModal.title')}
                                defaultValues={editAddress}
                                handleFormCancel={() => {}}
                                handleFormSubmit={(values) => {
                                    handleEditAddressSubmit(values, editAddressIndex);
                                }}
                                centerFormSubmit={true}
                                showCancel={false}
                                isAddressVerifying={isAddAddressBusy}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleEditAddressSubmit = (values: ProfileObjectAddressPayload, editAddressIndex: Number) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };

        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                const address = { ...values, zipcode: Zip5, zipcodeFour: Zip4 };
                dispatch(
                    accountUpdateAddressToProfileRoutine({
                        index: editAddressIndex,
                        address,
                        onSuccess: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    size: 'lg',
                                    type: 'danger',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            headerText={t('modals.updateProfile.error')}
                                            icon="alert"
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            },
            onFailure: ({ error }) => {
                if (values.onFailure && error && !error.message) {
                    values.onFailure(error);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    return (
        <>
            <Spinner isVisible={isAddressBusy} t={t} />
            {sortDefaultAddresses.length === 0 && (
                <Container fluid className="my-4">
                    <p>{t('pages.profile.shippingAddress.empty')}</p>
                </Container>
            )}
            {sortDefaultAddresses.length > 0 && (
                <div className="d-flex flex-column">
                    {sortDefaultAddresses.map((value, index) => {
                        return (
                            <AddressCard
                                key={index}
                                index={index}
                                defaultAddress={value.defaultAddress}
                                address1={value.address1}
                                address2={value.address2}
                                city={value.city}
                                state={value.state}
                                zipcode={value.zipcode}
                                zipcodeFour={value.zipcodeFour}
                                isChecked={value.isChecked}
                                openEdit={() => handleEditAddressClick(value)}
                                addressType={value.addressType}
                                showLabel={showLabels}
                                onSetAsDefault={() => handleShippingAddressChange(value)}
                                handleSetAsShipping={onSetAsShipping ? () => onSetAsShipping(value) : undefined}
                            />
                        );
                    })}
                </div>
            )}
            {addNewAddressButtonLabel && (
                <div className="add-address-btn-container">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="sm-full"
                        label={addNewAddressButtonLabel}
                        type="button"
                        variant="text-blue"
                        onClick={handleAddNewAddressClick}
                        dataGAFormName="addresses"
                    />
                </div>
            )}
        </>
    );
};

export default ShippingAddresses;
