import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import { CreditCardPayload } from 'state/account/account.services';
import {
    JoinMembershipPlanAcceptOrDeclineInvitePayload,
    JoinMembershipPlanAcceptOrDeclineInviteResponse
} from 'types/join-membership-plan';

import {
    MembershipAddMemberResponseData,
    MembershipBirdiSelectFormularyPayload,
    MembershipDefaultPaymentMethodResponse,
    MembershipDetails,
    MembershipGenerateScheduleLinkPayload,
    MembershipGenerateScheduleLinkResponse,
    MembershipInvitationsResponse,
    MembershipLeavePlanPayload,
    MembershipPaymentMethodPayload,
    MembershipPersonalDetailsFormValues,
    MembershipPlansDetailsResponse,
    MembershipPreAuthorizePaymentRequest,
    MembershipPreAuthorizePaymentResponse,
    MembershipPricingDetailsResponse,
    MembershipRemoveFromPlanPayload,
    MembershipRemoveInviteePayload,
    MembershipRemoveInviteResponse,
    MembershipRemoveResponse,
    MembershipSendInvitePayload,
    MembershipSendInviteResponse,
    MembershipTelehealthVisitsResponse,
    MembershipUpgradePlanPayload,
    MembershipUpgradePlanResponse,
    MembershipViewVisitsResponse
} from 'types/membership';

import axiosClient from 'util/axiosClient';

export default class MembershipService {
    static dependents() {
        return {
            verify: async (payload: MembershipPersonalDetailsFormValues) => {
                return Promise.resolve(payload);
            }
        };
    }
    static membershipDetails() {
        return {
            get: async (payload: Pick<MembershipDetails, 'membershipId'>): Promise<MembershipDetails> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Membership/${payload}/details`);
                return response.data;
            }
        };
    }
    static membershipAddMember() {
        return {
            post: async (payload: MembershipAddMemberResponseData): Promise<MembershipDetails> => {
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/Membership/AddMember`, payload);
                return response.data;
            }
        };
    }
    static membershipPaymentMethods() {
        return {
            get: async (): Promise<MembershipPaymentMethodPayload> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Membership/GetBirdiPaymentMethods`);
                return response.data;
            }
        };
    }
    static membershipSendInvite() {
        return {
            post: async (payload: MembershipSendInvitePayload): Promise<MembershipSendInviteResponse> => {
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/Membership/SendInvite`, payload);
                return response.data;
            }
        };
    }
    static membershipGenerateScheduleLink() {
        return {
            post: async (
                payload: MembershipGenerateScheduleLinkPayload
            ): Promise<MembershipGenerateScheduleLinkResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Membership/GenerateScheduleLink?epostPatientNumber=${
                        payload.epostPatientNumber
                    }${payload.cardSeqNum ? `&cardSeqNum=${payload.cardSeqNum}` : undefined}`
                );
                return response.data;
            }
        };
    }

    static membershipViewVisits() {
        return {
            post: async (epostPatientNumber: string): Promise<MembershipViewVisitsResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Membership/CancelVisit?epostPatientNumber=${epostPatientNumber}`
                );
                return response.data;
            }
        };
    }

    static membershipBirdiSelectFomulary() {
        return {
            get: async (): Promise<MembershipBirdiSelectFormularyPayload> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/membership/druglist`, {
                    headers: { Authorization: null }
                });
                return response.data;
            }
        };
    }

    static membershipGetPlansDetails() {
        return {
            get: async (): Promise<MembershipPlansDetailsResponse> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Membership/GetPlanDetails`);
                return response.data;
            }
        };
    }

    static membershipTelehealthVisits(epostPatientNumber: string) {
        return {
            get: async (): Promise<MembershipTelehealthVisitsResponse> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/Membership/TelehealthPendingVisit/?epostPatientNumber=${epostPatientNumber}`
                );
                return response.data;
            }
        };
    }

    static membershipRemoveMember() {
        return {
            removeFromPlan: async (payload: MembershipRemoveFromPlanPayload): Promise<MembershipRemoveResponse> => {
                const response = await axiosClient.delete(
                    `${GATSBY_API_BASE_URL}/api/Membership/RemoveMember?ePostPatientNum=${payload.ePostPatientNum}&membershipId=${payload.membershipId}`
                );
                return response.data;
            },
            leavePlan: async (payload: MembershipLeavePlanPayload): Promise<MembershipRemoveResponse> => {
                const response = await axiosClient.delete(
                    `${GATSBY_API_BASE_URL}/api/Membership/LeaveMembershipPlan?ePostPatientNum=${payload.ePostPatientNum}`
                );
                return response.data;
            }
        };
    }

    static membershipRemoveInvitee() {
        return {
            delete: async (payload: MembershipRemoveInviteePayload): Promise<MembershipRemoveInviteResponse> => {
                const response = await axiosClient.delete(
                    `${GATSBY_API_BASE_URL}/api/Membership/RemoveInvitee?epostPatientNum=${payload.ePostPatientNumber}&ownerMembershipId=${payload.ownerMembershipId}`
                );
                return response.data;
            }
        };
    }

    static membershipDefaultPayment() {
        return {
            put: async (payload: CreditCardPayload): Promise<MembershipDefaultPaymentMethodResponse> => {
                const response = await axiosClient.put(
                    `${GATSBY_API_BASE_URL}/api/Membership/SetDefaultMembershipCard`,
                    payload
                );
                return response.data;
            }
        };
    }

    static membershipUpgradePlan() {
        return {
            get: async (payload: {
                epostPatientNum: string;
                promoCode?: string;
            }): Promise<MembershipPricingDetailsResponse> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/Membership/upgrade/pricing?EPostPatientNumber=${
                        payload.epostPatientNum
                    }${payload?.promoCode ? `&promocode=${payload.promoCode}` : ''}`
                );

                return response.data;
            },
            put: async (payload: MembershipUpgradePlanPayload): Promise<MembershipUpgradePlanResponse> => {
                const response = await axiosClient.put(
                    `${GATSBY_API_BASE_URL}/api/Membership/UpgradeMembership`,
                    payload
                );
                return response.data;
            }
        };
    }

    static membershipPreAuthorizePayment() {
        return {
            post: async (
                payload: MembershipPreAuthorizePaymentRequest
            ): Promise<MembershipPreAuthorizePaymentResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Membership/PreAuthorizePayment`,
                    payload
                );
                return response.data;
            }
        };
    }

    static membershipInvitations() {
        return {
            get: async (): Promise<MembershipInvitationsResponse> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Membership/Invitations`);
                return response.data;
            }
        };
    }

    static membershipAcceptOrDeclineInvite() {
        return {
            post: async (
                payload: JoinMembershipPlanAcceptOrDeclineInvitePayload
            ): Promise<JoinMembershipPlanAcceptOrDeclineInviteResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Membership/Invitations/${payload.inviteId}/response`,
                    {
                        status: payload.status
                    }
                );
                return response.data;
            }
        };
    }
}
